define("give-public/models/suggestedfund", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    is_published: _emberData.default.attr('boolean'),
    is_athletics_fund: _emberData.default.attr('boolean'),
    is_athletics_benefits_fund: _emberData.default.attr('boolean'),
    title: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string')
  });
  _exports.default = _default;
});