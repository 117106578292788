define("give-public/services/pledgecart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    items: null,
    total: null,
    localStorage: null,
    donorId: null,
    pledgeNum1: null,
    pledgeNum2: null,
    pledgeNum3: null,
    isDisplaying: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('items', []);
      this.set('total', 0);
      this.set('total', 0);
      this.set('donorId', '');
      this.set('pledgeNum1', '');
      this.set('pledgeNum2', '');
      this.set('pledgeNum3', '');
    },
    add: function add(item) {
      this.items.pushObject(item);
      this.updateCartTotal();
    },
    updateCartTotal: function updateCartTotal() {
      var total = this.items.reduce(function (accumulator, item) {
        var inputValue = item.amount;
        var amount = 0;
        if (inputValue) {
          if (inputValue.substring(0, 1) === '$') {
            amount = parseFloat(inputValue.substring(1).replace(/,/g, ''));
          } else {
            amount = parseFloat(inputValue.replace(/,/g, ''));
          }
        }
        if (isNaN(amount)) {
          return accumulator;
        } else {
          return accumulator + amount;
        }
      }, 0);
      this.set('total', total);
      this._dumpToLocalStorage();
    },
    payLoad: function payLoad() {
      return {
        items: this.get('items'),
        total: this.get('total')
      };
    },
    pushPayload: function pushPayload(payLoad) {
      this.set('items', payLoad['items']);
      this.set('total', payLoad['total']);
    },
    _dumpToLocalStorage: function _dumpToLocalStorage() {
      if (this.localStorage) {
        window.localStorage.setItem('pledgecart', JSON.stringify(this.payLoad()));
      }
    }
  });
  _exports.default = _default;
});