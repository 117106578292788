define("give-public/services/tracking", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    items: Ember.A([]),
    add: function add(item) {
      this.items.pushObject(item);
    },
    remove: function remove(item) {
      this.items.removeObject(item);
    },
    empty: function empty() {
      this.items.clear();
    }
  });
  _exports.default = _default;
});