define("give-public/controllers/searchdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      this.initState();
    },
    initState: function initState() {
      this.set('queryParams', ['term']);
      this.set('term', '');
      this.set('funds', []);
      var funds = this.get('funds');
      this.set('lastTerm', this.get('term'));
      this.set('lastFunds', funds);
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      },
      refreshData: function refreshData() {
        var lastTerm = this.get('term');
        var lastFunds = this.get('funds');
        this.set('lastTerm', lastTerm);
        this.set('lastFunds', lastFunds);
      }
    }
  });
  _exports.default = _default;
});