define("give-public/components/static-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    params: Ember.computed.alias('router.currentRoute.queryParams'),
    staticContent: Ember.computed(function () {
      var params = this.get('params');
      if (params && params.page) {
        var path = "/".concat(params.page);
        return this.staticpages.findBy(function (page) {
          if (page.path == path) {
            return page;
          }
        });
      }
      return this.staticpages;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});