define("give-public/components/addtobasket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    cart: Ember.inject.service(),
    classNames: ['addtobasket-cta-wrap'],
    init: function init() {
      this._super.apply(this, arguments);
      this.label = this.label || "ADD TO BASKET";
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      add: function add(fundpage) {
        var _this = this;
        this.set('selected', true);
        var fundId = fundpage.id || fundpage.fund_id;
        if (fundId) {
          var fund = this.store.peekRecord('fund', fundId);
          var featuredfund = this.get('store').peekRecord('featuredfund', fundId);
          var funddetail = this.get('store').peekRecord('fundpage', fundId);
          if (fund) {
            this.cart.add(fund);
          } else {
            if (this.default_gift_value) {
              fundpage.amount = this.default_gift_value;
            }
            this.cart.add(fundpage);
            if (this.default_gift_frequency && this.default_gift_frequency !== '') {
              this.cart.set('isRecurring', true);
              if (this.default_gift_frequency === 'MON') {
                this.cart.set('frequency', 'monthly');
              }
              if (this.default_gift_frequency === 'QTR') {
                this.cart.set('frequency', 'quarterly');
              }
              if (this.default_gift_frequency === 'ANN') {
                this.cart.set('frequency', 'annally');
              }
              if (this.default_gift_installments && this.default_gift_installments > 0) {
                var recurringTimes = this.default_gift_installments;
                this.cart.set('isUntilDate', true);
                this.cart.set('recurringTimes', recurringTimes);
                this.cart.calculateTotal();
                this.cart.getEndDate(this.default_gift_frequency, this.default_gift_installments).then(function (data) {
                  if (data['installments'] && Array.isArray(data['installments'])) {
                    _this.cart.set('recurringYear', data['stop_year']);
                    _this.cart.set('recurringMonth', data['stop_month']);
                    _this.cart.set('grandtotal', _this.cart.get('total') * recurringTimes);
                  }
                });
              }
            }
          }
          if (fund) {
            fund.set('selected', true);
          }
          if (featuredfund) {
            featuredfund.set('selected', true);
          }
          if (funddetail) {
            funddetail.set('selected', true);
          }
        } else {
          return;
        }
      }
    }
  });
  _exports.default = _default;
});