define("give-public/services/biggive-takeover-image", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    image: null,
    initialize: function initialize() {
      var self = this;
      try {
        // parse API response and resolve these variables
        var takeoverImageUrl = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/takeoverimages/");
        this.ajax.request(takeoverImageUrl).then(function (response) {
          if (response.results.length) {
            var results = response.results;
            var biggiveTakeoverImageJson = results.filter(function (obj) {
              return obj.name === 'BIGGIVE_TAKEOVER_BANNER';
            });
            self.set('image', biggiveTakeoverImageJson[0].image);
          }
        });
      } catch (error) {
        console.log('Could not get takeover image. Error: ' + error);
      }
    }
  });
  _exports.default = _default;
});