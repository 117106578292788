define("give-public/components/redirect", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    context: null,
    utilities: Ember.inject.service(),
    cart: Ember.inject.service(),
    tracking: Ember.inject.service(),
    params: Ember.computed.alias('router.currentRoute.queryParams'),
    init: function init() {
      this._super.apply(this, arguments);
    },
    willRender: function willRender() {
      var _this = this;
      var params = this.get('params');

      // taking care of the appeal code
      if (params.ac || params.cadsid) {
        if (params.ac) {
          var ac_item = {
            code: 'ac',
            value: params.ac
          };
          this.tracking.add(ac_item);
        }
        if (params.cadsid) {
          var cadsid_item = {
            code: 'cadsid',
            value: params.cadsid
          };
          this.tracking.add(cadsid_item);
        }
      }
      if (params.fund1 || params.freq) {
        var utilities = this.get('utilities');
        utilities.getBasketInfor(params).then(function (response) {
          var items = response.items;
          if (items && Array.isArray(items) && items.length > 0) {
            var self = _this;
            items.map(function (item) {
              if (item.published !== 'Not available' && item.title !== 'Not available') {
                item.name = item.title;
                if (self.cart.isAdded(item)) {
                  // retrieve previous cart item and remove it
                  var existing_item = self.cart._get(item);
                  if (existing_item) {
                    self.cart.remove(existing_item);
                  }
                }
                self.cart.add(item);
              }
            });
          }
          if (response.frequency && response.frequency.frequency_type) {
            _this.cart.set('isRecurring', true);
            _this.cart.set('frequency', response.frequency.frequency_type);
            if (response.frequency.stop_date && response.frequency.stop_date.year && response.frequency.stop_date.month) {
              _this.cart.set('isUntilDate', true);
              _this.cart.set('recurringYear', response.frequency.stop_date.year);
              _this.cart.set('recurringMonth', response.frequency.stop_date.month);
              if (params.inst) {
                _this.cart.set('recurringTimes', params.inst);
              }
            }
            if (response.frequency.stop_date === 'INDEFINITE') {
              _this.cart.set('isUntilDate', false);
            }
          }
          _this.cart.updateCartTotal();
        });
      }
    }
  });
  _exports.default = _default;
});