define("give-public/routes/searchdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      term: {
        replace: false,
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.query('searchdetailcopy', {
        search: params.term
      }).then(function (res) {
        return res;
      });
    },
    afterModel: function afterModel(model) {
      document.title = "Search for \"".concat(model.query.search, "\" - Give to Berkeley");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('funds', model);
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});