define("give-public/templates/components/basket-atheletics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eQasNsb3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\"],[11,\"class\",\"checkbox-container\"],[9],[0,\"\\n  \"],[1,[29,\"input\",null,[[\"type\",\"checked\",\"change\"],[\"checkbox\",[25,[\"hasDeclinedAthleticsBenefits\"]],[29,\"action\",[[24,0,[]],\"toggleAthleticsBenefitsElection\"],null]]]],false],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"checkmark\"],[9],[10],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"I decline Athletics benefits associated with this gift.\"],[10],[0,\"\\n  \"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/basket-atheletics.hbs"
    }
  });
  _exports.default = _default;
});