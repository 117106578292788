define("give-public/routes/p", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      page: {
        replace: false,
        refreshModel: true
      }
    },
    model: function model(params) {
      var staticpages = this.store.peekAll('staticpage');
      var page = params.page;
      return staticpages.find(function (result) {
        return result.path === "/".concat(page);
      });
    },
    afterModel: function afterModel(result) {
      document.title = "".concat(result.title, " - Give to Berkeley");
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('four04');
      }
    }
  });
  _exports.default = _default;
});