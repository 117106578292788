define("give-public/serializers/application", ["exports", "give-public/serializers/drf"], function (_exports, _drf) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _drf.default.extend({
    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, id, requestType) {
      if (primaryModelClass.modelName === 'areadetail' || primaryModelClass.modelName === 'featuredfund' || primaryModelClass.modelName === 'fund' || primaryModelClass.modelName === 'fundpage' || primaryModelClass.modelName === 'searchdetail' || primaryModelClass.modelName === 'searchdetailcopy' || primaryModelClass.modelName === 'tagdetail' || primaryModelClass.modelName === 'funddrive') {
        var funds = payload.results;
        var cart = window.localStorage.getItem('cart');
        var items = [];
        var cartObj;
        var fundsData;
        if (cart !== undefined) {
          cartObj = JSON.parse(cart);
          if (cartObj) {
            items = cartObj.items;
          }
        }
        fundsData = funds.map(function (fund) {
          if (primaryModelClass.modelName !== 'funddrive') {
            fund.amount = '';
            fund.selected = false;
            fund.pk = fund.id;
            if (!fund.name) {
              fund.name = fund.title;
            }
            if (fund.is_published === undefined) {
              fund.is_published = true;
            }
            if (items !== undefined && Array.isArray(items) && items.length > 0) {
              for (var i = 0; i < items.length; i++) {
                var item = items[i];
                if (item.pk === fund.pk) {
                  fund.amount = item.amount;
                  fund.selected = true;
                }
              }
            }
          }
          if ((primaryModelClass.modelName === 'fundpage' || primaryModelClass.modelName === 'funddrive') && fund.related_fund_pages && Array.isArray(fund.related_fund_pages) && fund.related_fund_pages.length > 0) {
            var related_fund_pages = fund.related_fund_pages.map(function (related_fund_page) {
              related_fund_page.amount = '';
              related_fund_page.selected = false;
              related_fund_page.pk = related_fund_page.id;
              if (!related_fund_page.name) {
                related_fund_page.name = related_fund_page.title;
              }
              for (var k = 0; k < items.length; k++) {
                var _item = items[k];
                if (_item.pk === related_fund_page.pk) {
                  related_fund_page.amount = _item.amount;
                  related_fund_page.selected = true;
                }
              }
              return related_fund_page;
            });
            fund.related_fund_pages = related_fund_pages;
            if (primaryModelClass.modelName === 'funddrive' && fund.related_fund_pages.length === 1) {
              fund.selected = fund.related_fund_pages[0].selected;
            }
          }
          return fund;
        });
        var convertedPayload = Object.assign({}, payload, {
          results: fundsData
        });
        return this._super(store, primaryModelClass, convertedPayload, id, requestType);
      } else {
        return this._super(store, primaryModelClass, payload, id, requestType);
      }
    }
  });
  _exports.default = _default;
});