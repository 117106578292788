define("give-public/templates/components/search-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "zY+lZfnY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"input\",null,[[\"id\",\"value\",\"key-up\",\"class\",\"placeholder\"],[[24,0,[\"name\"]],[24,0,[\"value\"]],[29,\"action\",[[24,0,[]],\"handleFilterEntry\"],null],\"light\",[24,0,[\"placeholder\"]]]]],false],[0,\"\\n\\n\"],[15,1,[[24,0,[\"results\"]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/search-list.hbs"
    }
  });
  _exports.default = _default;
});