define("give-public/templates/components/static-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bLuP99bw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"role\",\"main\"],[11,\"class\",\"main-container\"],[9],[0,\"\\n\\t\"],[7,\"section\"],[11,\"class\",\"hero-row\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"hero-image-wrap use-img-tag\"],[9],[0,\"\\n\\t\\t\\t\"],[5,\"hero-image\",[],[[\"@image\"],[[25,[\"staticContent\",\"image\"]]]]],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"hero-banner-content-wrap\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"article\"],[11,\"class\",\"hero-banner-content\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"h1\"],[11,\"class\",\"banner-title\"],[9],[1,[25,[\"staticContent\",\"title\"]],false],[10],[0,\"\\n\\n\\t\\t\\t\\t\"],[7,\"p\"],[11,\"class\",\"banner-text safe-html\"],[9],[1,[29,\"safe-html\",[[25,[\"staticContent\",\"description_short\"]]],null],false],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\\n\\t\"],[7,\"section\"],[11,\"class\",\"page-item safe-html\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[9],[1,[29,\"safe-html\",[[25,[\"staticContent\",\"body\"]]],null],false],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/static-content.hbs"
    }
  });
  _exports.default = _default;
});