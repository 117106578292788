define("give-public/templates/components/page-header-logo-only", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1Ep/8e5p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"header\"],[11,\"role\",\"main\"],[11,\"class\",\"header-logo-only\"],[9],[0,\"\\n\\t\"],[7,\"div\"],[11,\"class\",\"top-nav items-container\"],[9],[0,\"\\n\\t\\t\"],[7,\"div\"],[11,\"class\",\"centered\"],[9],[0,\"\\n\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"logo-container\"],[9],[0,\"\\n\"],[4,\"link-to\",null,[[\"title\",\"class\",\"route\"],[\"Give Home\",\"display-block\",\"home\"]],{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"img\"],[11,\"class\",\"UC_Berkeley_Logo\"],[11,\"alt\",\"UC Berkeley logo\"],[12,\"src\",[30,[[23,\"rootURL\"],\"/assets/img/uc-berkeley-logo/uc-berkeley-logo.jpg\"]]],[12,\"srcset\",[30,[[23,\"rootURL\"],\"/assets/img/uc-berkeley-logo/uc-berkeley-logo@2x.jpg 2x,\\n\\t\\t\\t\\t\\t\\t\\t\",[23,\"rootURL\"],\"/assets/img/uc-berkeley-logo/uc-berkeley-logo@3x.jpg 3x\"]]],[9],[10]],\"parameters\":[]},null],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/page-header-logo-only.hbs"
    }
  });
  _exports.default = _default;
});