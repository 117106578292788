define("give-public/templates/components/hero-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9PWJhDQq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\"],[11,\"alt\",\"hero-image\"],[12,\"class\",[30,[\"hero-image \",[29,\"if\",[[29,\"gt\",[[25,[\"cart\",\"counter\"]],0],null],\"fixed-mobile-header\",\"\"],null]]]],[12,\"src\",[29,\"safe-html\",[[29,\"concat\",[[25,[\"heroImageUrl\"]]],null]],null]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/hero-image.hbs"
    }
  });
  _exports.default = _default;
});