define("give-public/models/staticpage", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var _default = Model.extend({
    body: _emberData.default.attr('string'),
    created: _emberData.default.attr('string'),
    modified: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    is_live: _emberData.default.attr('boolean')
  });
  _exports.default = _default;
});