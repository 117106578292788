define("give-public/templates/components/addtobasket", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pu4uINOI",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\"],[12,\"disabled\",[23,\"selected\"]],[11,\"class\",\"addtobasket-cta\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"add\",[24,0,[\"fund\"]]],null]],[9],[0,\"\\n  \"],[1,[24,0,[\"label\"]],false],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/addtobasket.hbs"
    }
  });
  _exports.default = _default;
});