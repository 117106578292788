define("give-public/instance-initializers/takeover-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(app) {
    var _app$container = app.container,
      container = _app$container === void 0 ? app : _app$container;
    var myService = container.lookup('service:biggive-takeover-image');
    myService.initialize();
  }
  var _default = {
    initialize: initialize,
    name: 'takeover-image'
  };
  _exports.default = _default;
});