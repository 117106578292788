define("give-public/components/addtobasket-card", ["exports", "ember-resize-text/mixins/resize-text"], function (_exports, _resizeText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_resizeText.default, {
    tagName: 'li',
    classNames: ['addtobasket-card', 'small'],
    router: Ember.inject.service(),
    minSize: 13,
    maxSize: 24,
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    actions: {
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.id);
      }
    }
  });
  _exports.default = _default;
});