define("give-public/components/numeric-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // cmd, ctrl + paste
          key === 86 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      validateField: function validateField(event) {
        this.set('isDirty', true);
        var optional = this.get('optional');
        if (optional !== 'optional') {
          var fieldValue = event.target.value;
          var name = event.target.name;
          if (fieldValue && fieldValue.trim() !== '') {
            this.set('isInvalid', false);
          } else {
            this.set('isInvalid', true);
          }
          this.set('value', fieldValue.trim());
          this.send('updateAttr', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
        } else {
          return;
        }
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});