define("give-public/models/incident", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var _default = Model.extend({
    success: _emberData.default.attr('boolean'),
    challenge_ts: _emberData.default.attr()
  });
  _exports.default = _default;
});