define("give-public/components/add-to-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    calTitle: 'cal-title'
  });
  _exports.default = _default;
});