define("give-public/routes/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.peekAll('staticpage');
    },
    afterModel: function afterModel(results) {
      var faqContent = results.find(function (result) {
        return result.path === '/faq';
      });
      document.title = "".concat(faqContent.title, " - Give to Berkeley");
    },
    actions: {
      didTransition: function didTransition() {
        var _this = this;
        Ember.run.schedule('afterRender', function () {
          if (window.location.hash === "#contact-form") {
            var el = document.querySelectorAll(".form.faq-form");
            if (el) {
              el[0].scrollIntoView();
            }
          }
          var querystr = _this.controllerFor('faq').get('querystr');
          // ?anc=other-ways-to-give-2&open=true
          var ancParamFull = querystr.split("&")[0];
          var openParamFull = querystr.split("&")[1];
          var ancParam = ancParamFull.split("=")[1];
          var openParam = openParamFull.split("=")[1];
          console.log(ancParam);
          console.log(openParam);
          if (document.getElementById(ancParam)) {
            var accordion = document.getElementById(ancParam);
            accordion.setAttribute("open", "");
          }
        });
      },
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});