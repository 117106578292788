define("give-public/routes/vip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      var staticpages = this.store.peekAll('staticpage');
      var page = this.routeName;
      var p = staticpages.find(function (result) {
        return result.path === "/".concat(page);
      });
      return p;
    },
    afterModel: function afterModel(result) {
      document.title = "".concat(result.title, " - Give to Berkeley");
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('four04');
      }
    }
  });
  _exports.default = _default;
});