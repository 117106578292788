define("give-public/router", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('funddrive', {
      path: 'funddrive/:id'
    });
    this.route('faq');
    this.route('home');
    this.route('giftdetails');
    this.route('searchdetails');
    this.route('areadetails');
    this.route('tagdetails');
    this.route('fund', {
      path: 'fund/:id'
    });
    this.route('confirmation');
    this.route('paymypledge');
    this.route('p');
    this.route('failed');
    this.route('payroll');
    this.route('pledgeconfirmation');
    this.route('pledgefailed');
    this.route('schools-and-colleges');
    this.route('vip');
    this.route('five00');
    this.route('four04', {
      path: '/*path'
    });
    this.route('students');
    this.route('error', {
      path: '/error/:id'
    });
  });
  var _default = Router;
  _exports.default = _default;
});