define("give-public/components/gift-details", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    utilities: Ember.inject.service(),
    cart: Ember.inject.service(),
    tracking: Ember.inject.service(),
    isValidGift: false,
    response: null,
    caller: "giftdetails",
    biggivePhase: Ember.inject.service(),
    vip: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      var utilities = this.get('utilities');
      var gift_id = window.localStorage.getItem('gift_id');
      var self = this;
      if (this.failed && gift_id) {
        utilities.getTransactionResult(gift_id).then(function (response) {
          self.setFailedState();
          self.setFailedBasketState(response);
          self.setCountryState(response);
          self.setFailedBillingInfoState(response);
          self.trackGoogleEvent(response);
        });
      } else {
        self.setInitialState();
        self.setInitialBasketState();
        self.setInitialAthleticState();
        self.setCountryState();
        if (self.caller === "giftdetails") {
          self.setInitialBillingInfoState();
        }
      }
    },
    trackGoogleEvent: function trackGoogleEvent(data) {
      var items = data.basket.items.map(function (item) {
        var cart_item = {};
        cart_item.amount_of_donation = item.amount;
        cart_item.name = item.fund.title;
        return cart_item;
      });
    },
    setCountryState: function setCountryState(response) {
      var _this = this;
      var mailCountry = 'US';
      var billingCountry = 'US';
      var utilities = this.get('utilities');
      if (this.failed && response.tributenotification && response.tributenotification.address && response.tributenotification.address.country) {
        mailCountry = response.tributenotification.address.country;
      }
      if (this.failed && response.donor && response.donor.address && response.donor.address.country) {
        billingCountry = response.donor.address.country;
      }
      var billingState = null;
      if (this.failed && response.donor && response.donor.address && response.donor.address.state) {
        billingState = response.donor.address.state;
      }
      this.set('mailCountry', mailCountry);
      this.set('billingCountry', billingCountry);
      utilities.getCountries().then(function (data) {
        var mailCountries = [];
        var billingCountries = [];
        for (var i = 0; i < data.results.length; i++) {
          var result = data.results[i];
          result.itemValue = result.iso2;
          if (result.itemValue === mailCountry) {
            result.selected = true;
          }
          mailCountries.push(result);
        }
        for (var _i = 0; _i < data.results.length; _i++) {
          var _result = data.results[_i];
          _result.itemValue = _result.iso2;
          if (_result.itemValue === billingCountry) {
            _result.selected = true;
          }
          billingCountries.push(_result);
        }
        _this.set('mailCountries', mailCountries);
        _this.set('billingCountries', billingCountries);
      }).then(function () {
        utilities.getRegions(mailCountry).then(function (data) {
          var regions = data.map(function (result) {
            if (result.code2) {
              result.itemValue = result.code2;
            } else {
              result.itemValue = result.name;
            }
            return result;
          });
          _this.set('mailStates', regions);
        });
      }).then(function () {
        utilities.getRegions(billingCountry).then(function (data) {
          var regions = data.map(function (result) {
            if (result.code2) {
              result.itemValue = result.code2;
            } else {
              result.itemValue = result.name;
            }
            if (result.itemValue === billingState) {
              result.selected = true;
            }
            return result;
          });
          _this.set('billingStates', regions);
        });
      });
    },
    setFailedState: function setFailedState() {
      this.set('step', 'summary');
      this.set('notBasketValidated', false);
      this.set('notBillingInfoValidated', false);
    },
    setFailedBasketState: function setFailedBasketState(data) {
      this.cart.set('isRecurring', data.is_recurring);
      var items = data.basket.items.map(function (item) {
        var cart_item = {};
        cart_item.amount = item.amount;
        cart_item.pk = item.fund.fund_id;
        cart_item.id = item.fund.fund_id;
        cart_item.title = item.fund.title;
        cart_item.name = item.fund.title;
        cart_item.is_published = item.fund.is_published;
        return cart_item;
      });
      this.cart.set('items', items);
      if (data.frequency && data.frequency.frequency_type !== '') {
        this.cart.set('frequency', data.frequency.frequency_type);
        if (data.frequency.stop_month) {
          this.cart.set('isUntilDate', true);
          this.cart.set('recurringMonth', data.frequency.stop_month);
        }
        if (data.frequency.stop_year) {
          this.cart.set('recurringYear', data.frequency.stop_year);
        }
      } else {
        this.cart.set('frequency', 'monthly');
      }
      if (data.installments && Array.isArray(data.installments)) {
        this.cart.set('recurringTimes', data.installments.length);
      }
      this.cart.updateCartTotal();
      if (data.tribute && data.tribute.id) {
        this.set('isHonored', true);
        if (data.tribute.type && data.tribute.type === 'honor') {
          this.set('honor', 'honor');
          this.set('isInMemory', false);
          this.set('honorFirstName', data.tribute.forename);
          this.set('honorLastName', data.tribute.surname);
          this.set('honorMiddleName', data.tribute.middlename);
        } else {
          this.set('honor', 'memory');
          this.set('isInMemory', true);
          this.set('memoryFirstName', data.tribute.forename);
          this.set('memoryLastName', data.tribute.surname);
          this.set('memoryMiddleName', data.tribute.middlename);
        }
        if (data.tribute.notify_via) {
          if (data.tribute.notify_via === 'email') {
            this.set('mail', 'email');
            this.set('isEmail', true);
          } else {
            this.set('mail', 'mail');
            this.set('isEmail', false);
          }
        }
        if (data.tributenotification && data.tributenotification.id) {
          this.set('isNotified', true);
          if (data.tributenotification.email_address) {
            this.set('email', data.tributenotification.email_address);
          }
          if (data.tributenotification.address) {
            this.set('mailCountry', data.tributenotification.address.country);
            this.set('mailAddress', data.tributenotification.address.line1);
            this.set('mailApt', data.tributenotification.address.line2);
            this.set('mailCity', data.tributenotification.address.city);
            this.set('mailState', data.tributenotification.address.state);
            this.set('mailZip', data.tributenotification.address.postal_code);
          }
        } else {
          this.set('email', '');
          this.set('mailCountry', 'US');
          this.set('mailAddress', '');
          this.set('mailApt', '');
          this.set('mailCity', '');
          this.set('mailStatePlaceholder', 'State');
          this.set('mailState', '');
          this.set('mailZip', '');
        }
      } else {
        this.setInitialBasketState();
      }
      this.set('hasDeclinedAthleticsBenefits', data.has_declined_athletics_benefits);
    },
    setFailedBillingInfoState: function setFailedBillingInfoState(data) {
      this.set('isAnonymous', data.is_anonymous);
      if (data.is_joint) {
        this.set('isJoint', false);
      } else {
        this.set('isJoint', true);
      }
      this.set('isCompanyGift', data.donor.address.is_business);
      this.set('isNotCall', data.donor.phone.do_not_call);
      this.set('isMatchingGift', false);
      this.set('billingFirstName', data.donor.forename);
      this.set('billingMiddleName', data.donor.middlename);
      this.set('billingLastName', data.donor.surname);
      this.set('billingCountry', data.donor.address.country);
      this.set('billingAddress', data.donor.address.line1);
      this.set('billingAddressLine2', data.donor.address.line2);
      this.set('billingCity', data.donor.address.city);
      this.set('billingStatePlaceholder', 'State');
      this.getRegions('billingCountry', data.donor.address.country);
      this.set('billingState', data.donor.address.state);
      this.set('billingZip', data.donor.address.postal_code);
      this.set('billingEmail', data.donor.email_address.email_address);
      this.set('billingPhone', data.donor.phone.phone_number);
      this.set('phoneType', data.donor.phone.type);
      this.set('matchingCompany', data.matching);
      if (data.matching_hepdata) {
        this.set('isMatchingGift', true);
        if (data.matching_hepdata.company_id) {
          this.set('matchingCompanyId', data.matching_hepdata.company_id);
        }
        if (data.matching_hepdata.name) {
          this.set('matchingCompany', data.matching_hepdata.name);
        }
      } else {
        this.set('isMatchingGift', false);
      }
    },
    // general page load initial states
    setInitialState: function setInitialState() {
      this.set('step', 'giftDetails');
      this.set('notBasketValidated', true);
      this.set('notBillingInfoValidated', true);
    },
    getRegions: function getRegions(name, value) {
      var _this2 = this;
      var utilities = this.get('utilities');
      utilities.getRegions(value).then(function (data) {
        var regions = data.map(function (result) {
          if (result.code2) {
            result.itemValue = result.code2;
          } else {
            if (result.name) {
              result.itemValue = result.name;
            } else {
              var countries;
              if (name === 'mailCountry') {
                countries = _this2.get('mailCountries');
              } else {
                countries = _this2.get('billingCountries');
              }
              for (var i = 0; i < countries.length; i++) {
                var country = countries[i];
                if (country['itemValue'] === value) {
                  result.itemValue = country['name'];
                  result.name = country['name'];
                }
              }
            }
          }
          return result;
        });
        if (name === 'mailCountry') {
          _this2.set('mailStates', regions);
          if (value === 'US') {
            _this2.set('mailStatePlaceholder', 'State');
            _this2.set('mailZipPlaceholder', 'ZIP');
          } else {
            _this2.set('mailStatePlaceholder', 'Province');
            _this2.set('mailZipPlaceholder', 'Postal Code');
          }
        }
        if (name === 'billingCountry') {
          _this2.set('billingStates', regions);
          if (value === 'US') {
            _this2.set('billingStatePlaceholder', 'State');
            _this2.set('billingZipPlaceholder', 'ZIP');
          } else {
            _this2.set('billingStatePlaceholder', 'Province');
            _this2.set('billingZipPlaceholder', 'Postal Code');
          }
        }
      });
    },
    // gift details page initial states
    setInitialBasketState: function setInitialBasketState() {
      this.set('isHonored', false);
      this.set('honor', 'honor');
      this.set('isInMemory', false);
      this.set('isNotified', false);
      this.set('mail', 'email');
      this.set('isEmail', true);
      this.set('memoryFirstName', '');
      this.set('memoryLastName', '');
      this.set('honorFirstName', '');
      this.set('honorLastName', '');
      this.set('email', '');
      this.set('mailAddress', '');
      this.set('mailApt', '');
      this.set('mailCity', '');
      this.set('mailStatePlaceholder', 'State');
      this.set('mailState', '');
      this.set('mailZipPlaceholder', 'ZIP');
      this.set('mailZip', '');
    },
    // athletic section initial states
    setInitialAthleticState: function setInitialAthleticState() {
      this.set('hasDeclinedAthleticsBenefits', null);
    },
    // billing information page initial states
    setInitialBillingInfoState: function setInitialBillingInfoState() {
      this.set('isAnonymous', false);
      this.set('isJoint', false);
      this.set('isCompanyGift', false);
      this.set('isNotCall', false);
      this.set('isMatchingGift', false);
      this.set('billingFirstName', '');
      this.set('billingMiddleName', '');
      this.set('billingLastName', '');
      this.set('billingAddress', '');
      this.set('billingAddressLine2', '');
      this.set('billingCity', '');
      this.set('billingStatePlaceholder', 'State');
      this.set('billingState', '');
      this.set('billingZip', '');
      this.set('billingZipPlaceholder', 'ZIP');
      this.set('billingEmail', '');
      this.set('billingPhone', '');
      this.set('phoneType', 'mobile');
      this.set('matchingCompany', '');
      this.set('matchingCompanyId', null);
      this.set('lastMatchingCompany', '');
    },
    actions: {
      goTo: function goTo(step) {
        this.set('step', step);
      },
      // action to submit gift forms
      submitGift: function submitGift(token) {
        var _this3 = this;
        var gift = {
          token: token,
          grand_total: this.cart.get('isRecurring') ? this.cart.get('grandtotal') : this.cart.get('total'),
          is_anonymous: this.get('isAnonymous'),
          is_joint: this.get('isJoint') ? false : true,
          is_pledge: false,
          is_payroll: false,
          bill_to: {
            forename: this.get('billingFirstName'),
            middlename: this.get('billingMiddleName'),
            surname: this.get('billingLastName'),
            address: {
              country: this.get('billingCountry'),
              line1: this.get('billingAddress'),
              line2: this.get('billingAddressLine2'),
              city: this.get('billingCity'),
              state: this.get('billingState'),
              postal_code: this.get('billingZip'),
              is_business: this.get('isCompanyGift')
            },
            contact_info: {
              email: this.get('billingEmail'),
              phone_number: this.get('billingPhone'),
              type: this.get('phoneType'),
              do_not_call: this.get('isNotCall')
            }
          }
        };
        var items = JSON.parse(JSON.stringify(this.cart.get('items')));
        // set athletics benefits elections from rules in cart service
        gift = this.cart.setAthleticsBenefitsElection(gift, items, this.hasDeclinedAthleticsBenefits);
        var basketItems = items.map(function (item) {
          var amount = item.amount;
          var itemAmount = 0;
          if (typeof amount == 'string') {
            if (amount.substring(0, 1) === '$') {
              itemAmount = parseFloat(amount.substring(1).replace(/,/g, '')).toFixed(2);
            } else {
              itemAmount = parseFloat(amount.replace(/,/g, '')).toFixed(2);
            }
            item.amount = itemAmount;
          }
          return item;
        });
        gift['items'] = basketItems;
        if (this.get('isMatchingGift')) {
          if (this.get('matchingCompanyId')) {
            gift.matching_hepdata = {
              name: this.get('matchingCompany'),
              company_id: this.get('matchingCompanyId')
            };
          } else {
            gift.matching = {
              company_name: this.get('matchingCompany')
            };
          }
        }
        if (this.cart.get('isRecurring')) {
          gift.frequency = {
            frequency_type: this.cart.get('frequency'),
            stop_date: {
              month: String(this.cart.get('recurringMonth')),
              year: String(this.cart.get('recurringYear'))
            }
          };
        }
        if (this.get('isHonored')) {
          gift.tribute = {
            forename: this.get('isInMemory') ? this.get('memoryFirstName') : this.get('honorFirstName'),
            surname: this.get('isInMemory') ? this.get('memoryLastName') : this.get('honorLastName'),
            type: this.get('isInMemory') ? 'memory' : 'honor',
            notify_via: this.get('isEmail') ? 'email' : 'mail'
          };
          if (this.get('isNotified')) {
            if (this.get('isEmail')) {
              gift.tribute.notification = {
                forename: this.get('isInMemory') ? this.get('emailFirstName') : gift.tribute.forename,
                middlename: '',
                surname: this.get('isInMemory') ? this.get('emailLastName') : gift.tribute.surname,
                email_address: this.get('email')
              };
            } else {
              gift.tribute.notification = {
                forename: this.get('isInMemory') ? this.get('mailFirstName') : gift.tribute.forename,
                middlename: '',
                surname: this.get('isInMemory') ? this.get('mailLastName') : gift.tribute.surname,
                address: {
                  country: this.get('mailCountry'),
                  line1: this.get('mailAddress'),
                  line2: this.get('mailApt'),
                  city: this.get('mailCity'),
                  state: this.get('mailState'),
                  postal_code: this.get('mailZip')
                }
              };
            }
          }
        }
        var currentPhase = this.biggivePhase.getPhase('livePhase');
        if (currentPhase) {
          this.tracking.add({
            code: 'ac',
            value: "".concat(_environment.default.APP.defaultBigGiveTrackingCode)
          });
        }
        var tracking_items = this.tracking.get('items');
        if (tracking_items && Array.isArray(tracking_items) && tracking_items.length > 0) {
          gift.tracking = tracking_items;
        }
        var submit = this.get('utilities');
        submit.createGift(gift).then(function (response) {
          _this3.set('isValidGift', true);
          _this3.set('response', response);
          _this3.send('goTo', 'summary');
        }).catch(function (errorObj) {
          _this3.set('isValidGift', false);
          if (errorObj.payload) {
            if (typeof errorObj.payload === 'string' || errorObj.payload instanceof String) {
              _this3.send('goTo', 'summary');
            } else {
              _this3.router.transitionTo('error', errorObj.payload);
            }
          }
        });
      },
      // actions related to basket details page
      selectResult: function selectResult(result) {
        this.set('matchingCompany', result.name);
        this.set('lastMatchingCompany', result.name);
        if (result && result.company_id) {
          this.set('matchingCompanyId', result.company_id);
        }
      },
      toggleHonored: function toggleHonored() {
        this.toggleProperty('isHonored');
        this.send('resetBasketValidation', false);
      },
      honorSwitch: function honorSwitch(honor) {
        this.set('honor', honor);
        if (honor === 'memory') {
          this.set('isInMemory', true);
        } else {
          this.set('isInMemory', false);
        }
      },
      toggleMail: function toggleMail() {
        this.toggleProperty('isNotified');
        this.send('resetBasketValidation', false);
      },
      toggleAthleticsBenefitsElection: function toggleAthleticsBenefitsElection() {
        this.toggleProperty('hasDeclinedAthleticsBenefits');
        console.log(this.get('hasDeclinedAthleticsBenefits'));
      },
      changeMailType: function changeMailType(mail) {
        this.set('mail', mail);
        if (mail === 'email') {
          this.set('isEmail', true);
        } else {
          this.set('isEmail', false);
        }
      },
      resetBasketValidation: function resetBasketValidation(flag) {
        if (flag) {
          this.set('notBasketValidated', false);
        } else {
          this.set('notBasketValidated', true);
        }
      },
      // actions related to billing information page
      resetBillingInfoValidation: function resetBillingInfoValidation(flag) {
        if (flag) {
          this.set('notBillingInfoValidated', false);
        } else {
          this.set('notBillingInfoValidated', true);
        }
      },
      updateFieldValue: function updateFieldValue(name, value) {
        var self = this;
        self.set("".concat(name), value);
        if (name === 'mailCountry' || name === 'billingCountry') {
          if (value !== '') {
            self.getRegions(name, value);
          }
        }
      },
      toggleAnonymous: function toggleAnonymous() {
        this.toggleProperty('isAnonymous');
      },
      toggleJoint: function toggleJoint() {
        this.toggleProperty('isJoint');
      },
      toggleCompanyGift: function toggleCompanyGift() {
        this.toggleProperty('isCompanyGift');
      },
      toggleNotCall: function toggleNotCall() {
        this.toggleProperty('isNotCall');
      },
      toggleMatchingGift: function toggleMatchingGift() {
        this.toggleProperty('isMatchingGift');
      }
    }
  });
  _exports.default = _default;
});