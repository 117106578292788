define("give-public/routes/four04", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      document.title = '404 - Give to Berkeley';
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('four04');
      }
    }
  });
  _exports.default = _default;
});