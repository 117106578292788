define("give-public/services/validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    validateEmail: function validateEmail(email) {
      var re = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      var passed = re.test(String(email).toLowerCase());
      if (passed) {
        return true;
      }
      return false;
    }
  });
  _exports.default = _default;
});