define("give-public/components/hero-image", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.useImgTag = true;
      this.image = this.image || "";
    },
    biggivePhase: Ember.inject.service(),
    biggiveTakeoverImage: Ember.inject.service(),
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    tagName: 'div',
    classNames: ['hero-image-wrap'],
    attributeBindings: ['style'],
    classNameBindings: ['useImgTag'],
    localImage: '/assets/img/home-hero/home-hero.jpg',
    isFundPage: Ember.computed('router.currentRouteName', function () {
      if (this.router.currentRouteName === 'fund') {
        return true;
      }
      return false;
    }),
    isFundDrivePage: Ember.computed('router.currentRouteName', function () {
      if (this.router.currentRouteName === 'funddrive') {
        return true;
      }
      return false;
    }),
    customImageFirstUrl: Ember.computed('image', function () {
      if (this.image) {
        return "".concat(_environment.default.APP.googleStorageImageBaseUrl) + this.image;
      }
      if (this.biggivePhase.livePhase) {
        return "".concat(_environment.default.APP.googleStorageImageBaseUrl) + this.biggiveTakeoverImage.image;
      }
      return this.localImage;
    }),
    takeoverFirstUrl: Ember.computed('image', function () {
      if (this.biggivePhase.livePhase) {
        return "".concat(_environment.default.APP.googleStorageImageBaseUrl) + this.biggiveTakeoverImage.image;
      }
      if (this.image) {
        return "".concat(_environment.default.APP.googleStorageImageBaseUrl) + this.image;
      }
      return this.localImage;
    }),
    heroImageUrl: Ember.computed('image', function () {
      if (this.isFundPage || this.isFundDrivePage) {
        return this.customImageFirstUrl;
      }
      return this.takeoverFirstUrl;
    })
  });
  _exports.default = _default;
});