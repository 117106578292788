define("give-public/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    options: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('options', []);
      if (this.options && Array.isArray(this.options) && this.options.length > 0) {
        for (var i = 0; i < this.options.length; i++) {
          if (this.options[i]['itemValue'] === this.value) {
            Ember.set(this.options[i], 'selected', true);
          } else {
            Ember.set(this.options[i], 'selected', false);
          }
        }
      }
    },
    actions: {
      validateSelect: function validateSelect(event) {
        this.set('isDirty', true);
        var fieldValue = event.target.value;
        var name = event.target.name;
        if (fieldValue && fieldValue.trim() !== '') {
          this.set('isInvalid', false);
        } else {
          this.set('isInvalid', true);
        }
        this.set('value', fieldValue.trim());
        this.send('updateFieldValue', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
      },
      updateFieldValue: function updateFieldValue(name, value, isDirty, isInvalid) {
        this.sendAction('updateFieldValue', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});