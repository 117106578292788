define("give-public/components/autocomplete-result-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    result: Ember.computed('params.[]', function () {
      return this.params[0];
    }),
    // Attributes

    resultName: '',
    resultValue: '',
    // Methods
    getValue: function getValue(object, attrName) {
      if (object.get) {
        return object.get(attrName);
      } else {
        return object[attrName];
      }
    },
    // Properties

    name: Ember.computed('result', 'resultName', function () {
      return this.getValue(this.get('result'), this.get('resultName'));
    }),
    value: Ember.computed('result', 'resultValue', function () {
      return this.getValue(this.get('result'), this.get('resultValue'));
    }),
    highlightedValue: Ember.computed('highlightedResult', 'resultValue', function () {
      var value = '';
      if (this.get('highlightedResult')) {
        value = this.getValue(this.get('highlightedResult'), this.get('resultValue'));
      }
      return value;
    }),
    isHighlighted: Ember.computed('value', 'highlightedValue', function () {
      return this.get('value') === this.get('highlightedValue');
    }),
    actions: {
      selectResult: function selectResult(value) {
        if (value.instance == "publishedfundpage") {
          this.sendAction('selectResult', value);
        } else if (value.instance == "publishedfunddrive") {
          this.router.transitionTo('funddrive', value.fund_drive_id);
        } else {
          this.router.transitionTo(value.cleaned_path);
        }
      },
      add: function add(item) {
        var self = this;
        var id = item.pk;
        var fund = this.get('store').peekRecord('searchdetail', id);
        if (fund) {
          fund.set('selected', true);
          self.cart.add(item);
          self.cart.updateCartTotal();
        }
      },
      goToStaticPage: function goToStaticPage(item) {
        this.router.transitionTo(item.cleaned_path);
      },
      goToFundDrive: function goToFundDrive(item) {
        this.router.transitionTo('funddrive', item.fund_drive_id);
      }
    }
  });
  _exports.default = _default;
});