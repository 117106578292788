define("give-public/routes/schools-and-colleges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.store.peekAll('staticpage');
    },
    afterModel: function afterModel(results) {
      var schoolsContent = results.find(function (result) {
        return result.path === '/schools-and-colleges';
      });
      document.title = "".concat(schoolsContent.title, " - Give to Berkeley");
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('four04');
      }
    }
  });
  _exports.default = _default;
});