define("give-public/routes/funddrive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord('funddrive', params.id);
    },
    afterModel: function afterModel(model) {
      document.title = "".concat(model.title, " - Give to Berkeley");
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('four04', {
          wildcard: 'four04'
        });
      }
    }
  });
  _exports.default = _default;
});