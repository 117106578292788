define("give-public/routes/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    afterModel: function afterModel() {
      document.title = 'Incident logged - Give to Berkeley';
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      this.store.createRecord('incident', model);
      controller.set('incident', model);
    }
  });
  _exports.default = _default;
});