define("give-public/components/basket-frequency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this.setInitialFrequencyState();
    },
    setInitialFrequencyState: function setInitialFrequencyState() {
      this.set('isRecurring', this.cart.get('isRecurring'));
      this.set('isUntilDate', this.cart.get('isUntilDate'));
      if (this.cart.get('isRecurring')) {
        this.set('type', 'recurring');
      } else {
        this.set('type', 'onetime');
      }
      if (this.cart.get('isUntilDate')) {
        this.set('until', 'date');
      } else {
        this.set('until', 'cancel');
      }
      this.set('frequency', this.cart.get('frequency'));
      this.set('recurringMonth', this.cart.get('recurringMonth'));
      this.set('recurringYear', this.cart.get('recurringYear'));
      this.set('recurringYearInvalid', this.cart.get('recurringYearInvalid'));
      this.set('recurringMonthInvalid', this.cart.get('recurringMonthInvalid'));
    },
    actions: {
      typeChanged: function typeChanged(type) {
        this.set('type', type);
        if (type === 'recurring') {
          this.set('isRecurring', true);
          this.cart.set('isRecurring', true);
        } else {
          this.cart.set('isRecurring', false);
        }
        if (this.cart.validateTime()) {
          this.cart.updateCartGrandTotal();
        }
      },
      frequencyChanged: function frequencyChanged(frequency) {
        this.set('frequency', frequency);
        this.cart.set('frequency', frequency);
        this.cart.updateCartGrandTotal();
      },
      untilChanged: function untilChanged(until) {
        this.set('until', until);
        if (until === 'date') {
          this.set('isUntilDate', true);
          this.cart.set('isUntilDate', true);
        } else {
          this.set('isUntilDate', false);
          this.cart.set('isUntilDate', false);
        }
        if (this.cart.validateTime()) {
          this.cart.updateCartGrandTotal();
        }
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});