define("give-public/routes/home", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      return this.modelFor('application');
    } /*params*/,
    afterModel: function afterModel() {
      document.title = 'Home - Give to Berkeley';
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('staticpages', model.staticpages);
      controller.set('areas', model.areas);
      controller.set('featuredfunds', model.featuredfunds);
      controller.set('takeovers', model.takeovers);
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});