define("give-public/controllers/funddrive", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isShowingModal: false,
    tracking: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    isSingle: Ember.computed('model.related_fund_pages', function () {
      return this.model.related_fund_pages.length === 1;
    }),
    isMultiple: Ember.computed('model.related_fund_pages', function () {
      return this.model.related_fund_pages.length > 1;
    }),
    singlFundDrive: Ember.computed('model.related_fund_pages', function () {
      if (this.model.related_fund_pages.length === 1) {
        return this.model.related_fund_pages[0];
      }
    }),
    showProgress: Ember.computed('model.monetary_goal', function () {
      var item = {
        code: 'fdriveid',
        value: this.model.source_fund_drive
      };
      this.tracking.add(item);
      if (this.model.monetary_goal != 0) {
        return true;
      } else {
        return false;
      }
    }),
    timeLeft: Ember.computed('model.end_on', function () {
      var end_on = new Date(this.model.end_on).getTime();
      var current = new Date().getTime();
      var time_left_seconds = Math.floor((end_on - current) / 1000);
      var time_left_days = Math.floor(time_left_seconds / 60 / 60 / 24);
      var time_left_hours = Math.floor(time_left_seconds / 60 / 60);
      var time_left_minuets = Math.floor(time_left_seconds / 60);
      if (time_left_days > 1) {
        return "".concat(time_left_days, " days");
      } else if (time_left_days == 1) {
        return "".concat(time_left_days, " day");
      } else if (time_left_hours > 1) {
        return "".concat(time_left_hours, " hours");
      } else if (time_left_days == 1) {
        return "".concat(time_left_hours, " hour");
      } else if (time_left_minuets > 1) {
        return "".concat(time_left_minuets, " minutes");
      } else if (time_left_minuets == 1) {
        return "".concat(time_left_minuets, " minute");
      } else {
        return '';
      }
    }),
    isActive: Ember.computed('model.end_on', function () {
      var end_on = new Date(this.model.end_on).getTime();
      var current = new Date().getTime();
      var time_left_seconds = Math.floor((end_on - current) / 1000);
      var time_left_minuets = Math.floor(time_left_seconds / 60);
      if (time_left_minuets >= 1) {
        return true;
      } else {
        return false;
      }
    }),
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      }
    }
  });
  _exports.default = _default;
});