define("give-public/components/index-featured-funds", ["exports", "ember-resize-text/mixins/resize-text"], function (_exports, _resizeText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_resizeText.default, {
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    minSize: 18,
    maxSize: 26,
    actions: {
      add: function add(item) {
        var fund = this.get('store').peekRecord('featuredfund', item.id);
        if (fund && !fund.get('selected')) {
          fund.set('selected', true);
          this.cart.add(fund);
        }
      },
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.id);
      }
    }
  });
  _exports.default = _default;
});