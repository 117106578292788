define("give-public/services/bigmatch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    store: Ember.inject.service(),
    biggivePhase: Ember.inject.service(),
    currentAppealCode: null,
    init: function init() {
      this._super.apply(this, arguments);
    },
    loadAppealCode: function loadAppealCode(code) {
      this.currentAppealCode = code;
    },
    // main donor info form logic
    isBigMatchStatus: function isBigMatchStatus(phase) {
      try {
        // check for match appeal code in pre phase
        if (phase === 'pre') {
          // parse API response and resolve these variables
          var protoBigMatch = this.store.peekRecord('bigmatch', 1);
          if (protoBigMatch.code) {
            if (this.currentAppealCode === protoBigMatch.code) {
              return true;
            }
          }
        }

        // always return false for post or any other phase value
        return false;
      } catch (err) {
        return false;
      }
    }
  });
  _exports.default = _default;
});