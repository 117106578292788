define("give-public/adapters/application", ["exports", "give-public/adapters/drf", "ember-inflector"], function (_exports, _drf, _emberInflector) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /** For use with django REST framework
   *  http://dustinfarris.com/ember-django-adapter/
   *  http://www.django-rest-framework.org/
   **/
  var ApplicationAdapter = _drf.default.extend({
    addTrailingSlashes: true,
    // Need to Override buildURL because we are hosting multiple APIs
    // the switch statement evaluates the model and constructs the
    // appropriate API path
    buildURL: function buildURL(modelName, id, snapshot, requestType, query) {
      var url = this._super(modelName, id, snapshot, requestType, query);
      var path = '';
      switch (modelName) {
        case 'staticpage':
          path = "".concat(path, "give/staticpages");
          url = url.replace(/staticpages/g, path);
          break;
        case 'fundpage':
          path = "".concat(path, "give/fundpage");
          url = url.replace(/fundpage/g, path);
          break;
        case 'fundDetail':
          path = "".concat(path, "give/fund");
          url = url.replace(/fund/g, path);
          break;
        case 'static':
          path = "".concat(path, "give/static/?page= ");
          url = url.replace(/staticpages/g, path);
          break;
        case 'funddrive':
          path = "".concat(path, "give/funddrive");
          url = url.replace(/funddrive/g, path);
          break;
        case 'featuredfund':
          path = "".concat(path, "give/funds/featured");
          url = url.replace(/featuredfunds/, path);
          break;
        case 'suggestedfund':
          path = "".concat(path, "give/funds/suggested");
          url = url.replace(/suggestedfunds/, path);
          break;
        case 'area':
          path = "".concat(path, "give/areas");
          url = url.replace(/areas/g, path);
          break;
        case 'areadetail':
          path = "".concat(path, "give/area/").concat(query.slug);
          url = url.replace(/areadetails/g, path);
          break;
        case 'tag':
          path = "".concat(path, "give/tags");
          url = url.replace(/tags/g, path);
          break;
        case 'tagdetail':
          path = "".concat(path, "give/tag/").concat(query.tagId);
          url = url.replace(/tagdetails/g, path);
          break;
        case 'searchdetail':
          path = "".concat(path, "give/search");
          url = url.replace(/searchdetails/g, path);
          break;
        case 'searchdetailcopy':
          path = "".concat(path, "give/search");
          url = url.replace(/searchdetailcopies/g, path);
          break;
        case 'vip':
          path = "".concat(path, "biggive/vip");
          url = url.replace(/vips/g, path);
          break;
        case 'takeover':
          path = "".concat(path, "give/takeovers");
          url = url.replace(/takeovers/g, path);
          break;
        default:
      }
      return url;
    }
  });

  /**
   * Our drf api design uses single entity naming convention is post/id
   * ember appends a plural, this suppresses ember's default
   * otherwise the Adapter swtich case can handle it
   *
   * @ref https://api.emberjs.com/ember-data/3.6/classes/Ember.Inflector
   */
  var inflector = _emberInflector.default.inflector;
  inflector.uncountable('fundpage');
  inflector.uncountable('funddrive');
  var _default = ApplicationAdapter;
  _exports.default = _default;
});