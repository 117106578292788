define("give-public/components/pledge-redirect", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "give-public/config/environment"], function (_exports, _createForOfIteratorHelper2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var pledgeApiEndpoint = _environment.default.APP.pledgeApiEndpoint;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    params: Ember.computed.alias('router.currentRoute.queryParams'),
    pledgecart: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.getUrlParams();
    },
    getUrlParams: function getUrlParams() {
      var params = this.get('params');
      if (params.cadsid && params.pledge1) {
        var cadsParam = "cads_id=".concat(params.cadsid);
        var pledgeParam1 = "&pledge_id=".concat(params.pledge1);
        var pledgeParam2 = params.pledge2 ? "&pledge_id2=".concat(params.pledge2) : '';
        var pledgeParam3 = params.pledge3 ? "&pledge_id3=".concat(params.pledge3) : '';
        var pledgeApiCall = pledgeApiEndpoint + cadsParam + pledgeParam1 + pledgeParam2 + pledgeParam3;
        var self = this;
        this.get('ajax').request(pledgeApiCall).then(function (data) {
          var pledges = data['data'];
          if (pledges && Array.isArray(pledges) && pledges.length > 0) {
            var _iterator = (0, _createForOfIteratorHelper2.default)(pledges),
              _step;
            try {
              for (_iterator.s(); !(_step = _iterator.n()).done;) {
                var pledge = _step.value;
                if (pledge['allocation_code'] && pledge['long_name']) {
                  // create pledge shopping cart item
                  var item = {
                    id: pledge.allocation_code,
                    pk: pledge.allocation_code,
                    non_give_fund: pledge.allocation_code,
                    amount: 0,
                    selected: true,
                    name: pledge.long_name,
                    title: pledge.long_name,
                    fund_name: pledge.long_name
                  };
                  self.pledgecart.add(item);
                }
              }
            } catch (err) {
              _iterator.e(err);
            } finally {
              _iterator.f();
            }
            self.pledgecart.set('pledgesRetrieved', true);
            self.pledgecart.set('pledgeApiError', false);
          } else {
            // no pledges found
            self.pledgecart.set('donorId', params.cadsid);
            self.pledgecart.set('pledgeNum1', params.pledge1);
            self.pledgecart.set('pledgeNum2', params.pledge2);
            self.pledgecart.set('pledgeNum3', params.pledge3);
            self.pledgecart.set('pledgesRetrieved', false);
            self.pledgecart.set('pledgeApiError', true);
          }
        });
      } else {
        this.pledgecart.set('pledgesRetrieved', false);
        this.pledgecart.set('pledgeApiError', false);
      }
      this.pledgecart.set('isDisplaying', true);
    }
  });
  _exports.default = _default;
});