define("give-public/initializers/ember-anchor", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    var application = arguments[1] || arguments[0];
    var emberAnchor = _environment.default.emberAnchor;
    application.register('config:ember-anchor', emberAnchor, {
      instantiate: false
    });
  }
  var _default = {
    name: 'ember-anchor',
    initialize: initialize
  };
  _exports.default = _default;
});