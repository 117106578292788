define("give-public/templates/failed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cBMJxBk0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-container\"],[9],[0,\"\\n\\t\"],[7,\"main\"],[11,\"class\",\"page-container\"],[9],[0,\"\\n\\t\\t\"],[7,\"section\"],[11,\"class\",\"main-content-container\"],[9],[0,\"\\n\\t\\t\\t\"],[1,[29,\"page-header\",null,[[\"class\",\"selectResult\"],[\"no-sidebar\",\"selectResult\"]]],false],[0,\"\\n\\t\\t\\t\\n\\t\\t\\t\"],[1,[29,\"gift-details\",null,[[\"failed\"],[true]]],false],[0,\"\\t\\t\\n\\t\\t\"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/failed.hbs"
    }
  });
  _exports.default = _default;
});