define("give-public/helpers/ember-format-currency", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.emberFormatCurrency = emberFormatCurrency;
  function emberFormatCurrency(num /*, hash*/) {
    num = +num;
    if (isNaN(num)) {
      if (typeof num == 'string' && num[0] === '$') {
        num.substring(1).replace(/,/g, '');
      } else {
        return '';
      }
    }
    num = num.toFixed(2);
    return "$".concat(num.replace(/\B(?=(\d{3})+(?!\d)(?=\.))/g, ','));
  }
  var _default = Ember.Helper.helper(emberFormatCurrency);
  _exports.default = _default;
});