define("give-public/services/utilities", ["exports", "@babel/runtime/helpers/esm/typeof", "give-public/config/environment"], function (_exports, _typeof2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service('ajax'),
    lastTerm: null,
    /*
     * AJAX POST requests
     */
    createPledge: function createPledge(giftObj) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/checkout/pledge/create/");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        data: giftObj,
        xhrFields: {
          withCredentials: true
        }
      });
    },
    createPayroll: function createPayroll(payroll) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/payroll/");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        data: payroll,
        xhrFields: {
          withCredentials: true
        }
      });
    },
    createGift: function createGift(giftObj) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/checkout/gift/create/");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        data: giftObj,
        xhrFields: {
          withCredentials: true
        }
      });
    },
    /*
     * AJAX Get requests
     */
    getBasketInfor: function getBasketInfor(params) {
      var paramString = '';
      if ((0, _typeof2.default)(params) == 'object') {
        for (var key in params) {
          paramString += "".concat(key, "=").concat(params[key], "&");
        }
      }
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/transactions/helpers/basket?").concat(paramString);
      return this.get('ajax').request(endpoint);
    },
    getPayrollAuthenticated: function getPayrollAuthenticated() {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/payrolluserinfo");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        xhrFields: {
          withCredentials: true
        }
      });
    },
    getCountries: function getCountries() {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/transactions/helpers/countries");
      return this.get('ajax').request(endpoint);
    },
    getRegions: function getRegions(country_id) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/transactions/helpers/").concat(country_id, "/regions");
      return this.get('ajax').request(endpoint);
    },
    getStaticContent: function getStaticContent() {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/staticpages/");
      return this.get('ajax').request(endpoint);
    },
    getTransactionResult: function getTransactionResult(gift_id) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/checkout/gift/confirmation/");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        data: {
          gift_id: gift_id
        },
        xhrFields: {
          withCredentials: true
        }
      });
    },
    getPledgeTransactionResult: function getPledgeTransactionResult(gift_id) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/checkout/pledge/confirmation/");
      var aj = this.get('ajax');
      return aj.request(endpoint, {
        method: 'POST',
        contentType: 'application/json',
        data: {
          gift_id: gift_id
        },
        xhrFields: {
          withCredentials: true
        }
      });
    },
    getHepData: function getHepData(term) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/helpers/hepdata/search/").concat(term);
      return this.get('ajax').request(endpoint);
    },
    getCompanyDetails: function getCompanyDetails(company_id) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/give/helpers/hepdata/details/").concat(company_id);
      return this.get('ajax').request(endpoint);
    }
  });
  _exports.default = _default;
});