define("give-public/templates/components/cart-contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I4nPzinw",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"div\"],[11,\"class\",\"basket-container form\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"cart\",\"counter\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"ul\"],[11,\"class\",\"basket-list margin-bottom-20\"],[9],[0,\"\\n    \"],[7,\"li\"],[11,\"class\",\"basket-header margin-bottom-10\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"float-left width-70-percent\"],[9],[0,\"fund\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"float-right width-20-percent text-right\"],[9],[0,\"delete\"],[10],[0,\"\\n\\t\\t\\t\\t\"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n\\t\\t\\t\"],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"cart\",\"items\"]]],null,{\"statements\":[[0,\"      \"],[7,\"li\"],[11,\"class\",\"margin-bottom-20\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"span\"],[11,\"class\",\"float-left left\"],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n\"],[0,\"        \"],[7,\"a\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"remove\",[24,1,[]]],null]],[11,\"role\",\"button\"],[11,\"class\",\"float-right width-20-percent basket-action remove margin-right-10\"],[11,\"title\",\"Remove fund from your basket\"],[9],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"     \\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"button\"],[11,\"class\",\"basket-submit\"],[9],[0,\"GIVE NOW\"],[3,\"action\",[[24,0,[]],\"giveNow\"]],[10],[0,\"\\n  \"],[10],[0,\"\\n    \\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"p\"],[11,\"class\",\"basket-empty text-center\"],[9],[0,\"\\n      Currently Empty\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/cart-contents.hbs"
    }
  });
  _exports.default = _default;
});