define("give-public/components/index-areas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      goToArea: function goToArea(aoi) {
        this.router.transitionTo('areadetails', {
          queryParams: {
            slug: aoi.slug
          }
        });
      }
    }
  });
  _exports.default = _default;
});