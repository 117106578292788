define("give-public/templates/components/search-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZjaKMRJF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"search-list-item\"],[9],[0,\"\\n\\t\\t\"],[7,\"span\"],[9],[1,[24,0,[\"result\",\"name\"]],false],[10],[0,\"\\n\"],[4,\"if\",[[29,\"not\",[[24,0,[\"result\",\"selected\"]]],null]],null,{\"statements\":[[0,\"\\t\\t\\t\\t\"],[7,\"a\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"add\",[24,0,[\"result\"]]],null]],[11,\"role\",\"button\"],[11,\"class\",\"add-button\"],[11,\"title\",\"Select fund to put in your basket\"],[9],[0,\"\\n\\t\\t\\t\\t\\t\\t+\\n\\t\\t\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/search-list-item.hbs"
    }
  });
  _exports.default = _default;
});