define("give-public/components/autocomplete-input", ["exports", "ember-cli-keyboard-nav/mixins/keyboard-nav"], function (_exports, _keyboardNav) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_keyboardNav.default, {
    router: Ember.inject.service(),
    classNameBindings: ['hasFocus:apply-drop-shadow', 'isInvalid', 'isDirty'],
    store: Ember.inject.service(),
    showMore: null,
    results: null,
    // term: '',
    // lastTerm: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.initialState();
      this.updateTerm('').then(function (results) {
        return _this.set('results', results);
      });
      this.sendAction('refreshData');
    },
    initialState: function initialState() {
      this.set('results', []);
      this.set('term', '');
      this.set('lastTerm', '');
      this.set('autocomplete', '');
      this.set('name', '');
      this.set('resultName', 'name');
      this.set('resultValue', 'value');
      this.set('highlightedResultIndex', -1);
      // this.set('showMore', true);
    },

    hasFocus: false,
    // Properties
    hasResults: Ember.computed.notEmpty('results'),
    highlightedResult: Ember.computed('results.[]', 'highlightedResultIndex', function () {
      return this.get('results')[this.get('highlightedResultIndex')];
    }),
    currentURL: Ember.computed('router.currentURL', function () {
      return this.router.currentURL;
    }),
    // Hooks
    didInsertElement: function didInsertElement() {
      this.bindKeys(this.$('input[type="text"]'));
      // this.set('lastTerm', this.get('term'));
    },
    // Keyboard Nav actions
    onEnterPress: function onEnterPress() {
      var result = this.get("results")[this.get("highlightedResultIndex")];
      if (result) {
        this.send("selectResult", result);
      }
    },
    onEscPress: function onEscPress() {
      this.set('results', []);
    },
    onDownPress: function onDownPress() {
      var index = 0;
      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") + 1;
      }
      if (index > this.get("results").length - 1) {
        index = 0;
      }
      this.set("highlightedResultIndex", index);
    },
    onUpPress: function onUpPress() {
      var lastItem = this.get("results").length - 1;
      var index = lastItem;
      if (this.get("highlightedResultIndex") >= 0) {
        index = this.get("highlightedResultIndex") - 1;
      }
      if (index < 0) {
        index = lastItem;
      }
      this.set("highlightedResultIndex", index);
    },
    actions: {
      searchFunds: function searchFunds() {
        var term = this.get('lastTerm');
        if (this.currentURL.includes('searchdetails')) {
          this.sendAction('refreshData');
        } else {
          if (term && term !== '') {
            this.router.transitionTo('searchdetails', {
              queryParams: {
                term: term
              }
            });
          }
        }
        return false;
      },
      triggerKeyEvents: function triggerKeyEvents(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.

        // Enter
        if (key === 13) {
          // this.onEnterPress();
          if (this.caller !== "giftdetails") {
            this.send('searchFunds');
          } else {
            var term = this.get('lastTerm');
            if (term && term !== '') {
              this.send('updateTerm', term);
            }
          }
        }
        if (key === 38) {
          this.onUpPress();
        }
        if (key === 40) {
          this.onDownPress();
        }
        // // backspace
        // key === 8 ||
        // // tab
        // key === 9 ||
        // // delete
        // key === 46 ||
        // // comma
        // key === 188 ||
        // // period
        // key === 190 ||
        // // decimal point
        // key === 110 ||
        // // (
        // key === 57 ||
        // // )
        // key === 48 ||
        // // +
        // key === 187 ||
        // // -
        // key === 189 ||
        // // end, home, left arrow, up arrow, down arrow, right arrow
        // (key >= 35 && key <= 40) ||
        // // 0 - 9
        // (key >= 48 && key <= 57) ||
        // // numpad 0 - 9
        // (key >= 96 && key <= 105));
      },
      selectResult: function selectResult(result) {
        if (this.caller === "giftdetails") {
          this.set('term', result.name);
        }
        // this.set('results', []);
        this.selectResult(result);
        this.sendAction('hideDropDown');
      },
      updateInput: function updateInput(e) {
        var term = e.target.value;
        if (term && term != this.get('lastTerm')) {
          this.set('lastTerm', term);
          this.store.unloadAll('searchdetail');
          var defferred = 500;
          if (this.caller !== "giftdetails") {
            defferred = 600;
          }
          var self = this;
          Ember.run.later(function () {
            self.send('updateTerm', term);
          }, defferred);
        }
      },
      updateTerm: function updateTerm(term) {
        var _this2 = this;
        if (!term || term === '') {
          this.send('clearSearch');
        } else {
          var filterInputValue = this.term;
          var filterAction = this.updateTerm;
          this.set('hasFocus', true);
          if (term) {
            filterAction(filterInputValue).then(function (filterResults) {
              if (filterResults) {
                _this2.set('results', []);
                return filterResults.map(function (result) {
                  return result;
                });
              } else {
                return [];
              }
            }).then(function (results) {
              if (results && Array.isArray(results) && results.length > 0) {
                if (_this2.get('caller') !== 'giftdetails' && results.length > 8) {
                  _this2.set('showMore', true);
                  _this2.set('results', results.slice(0, 8));
                } else {
                  _this2.set('showMore', false);
                  _this2.set('results', results);
                }
              } else {
                _this2.set('showMore', false);
                _this2.set('results', []);
              }
              return false;
            });

            //const analytics = this.analytics;
            // let category = 'Company Search Term';
            // if (this.get('caller') !== 'giftdetails') {
            // 	category = 'Fund Search Term';
            // }
            //analytics.trackEvent('GoogleAnalytics', { action: term, category: categoy });
          }
        }

        return false;
      },
      clearSearch: function clearSearch() {
        this.set('results', []);
        this.set('term', '');
      },
      hideDropDown: function hideDropDown() {
        this.set('hasFocus', false);
        var defferred = 2000;
        if (this.get('caller') === 'giftdetails') {
          defferred = 600;
          this.set('isDirty', true);
          var fieldValue = this.get('term');
          if (fieldValue && fieldValue.trim() !== '') {
            this.set('isInvalid', false);
          } else {
            this.set('isInvalid', true);
          }
        }
        var self = this;
        Ember.run.later(function () {
          if (self.get('results')) {
            self.set('results', []);
          }
        }, defferred);
        return false;
      },
      showDropDown: function showDropDown() {
        this.set('hasFocus', true);
        var term = this.get('term');
        if (term && term !== '') {
          this.send('updateTerm', term);
        }
        return false;
      }
    }
  });
  _exports.default = _default;
});