define("give-public/templates/components/required-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+Cveh3uS",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"inputType\"]],\"textarea\"],null]],null,{\"statements\":[[0,\"\\t\"],[1,[29,\"textarea\",null,[[\"autocomplete\",\"name\",\"value\",\"focusOut\",\"updateAttr\",\"placeholder\",\"class\"],[\"off\",[25,[\"name\"]],[25,[\"value\"]],[29,\"action\",[[24,0,[]],\"validateField\"],null],[29,\"action\",[[24,0,[]],\"updateAttr\",[25,[\"name\"]],[25,[\"value\"]],[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null],[25,[\"placeholder\"]],[25,[\"style\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\"],[1,[29,\"input\",null,[[\"autocomplete\",\"type\",\"name\",\"value\",\"focusOut\",\"updateAttr\",\"placeholder\",\"class\"],[\"off\",\"text\",[25,[\"name\"]],[25,[\"value\"]],[29,\"action\",[[24,0,[]],\"validateField\"],null],[29,\"action\",[[24,0,[]],\"updateAttr\",[25,[\"name\"]],[25,[\"value\"]],[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null],[25,[\"placeholder\"]],[25,[\"style\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"error-message\"],[9],[0,\"Required\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/required-text-input.hbs"
    }
  });
  _exports.default = _default;
});