define("give-public/models/areadetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var _default = Model.extend({
    pk: _emberData.default.attr('string'),
    amount: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    is_published: _emberData.default.attr('boolean'),
    is_athletics_fund: _emberData.default.attr('boolean'),
    is_athletics_benefits_fund: _emberData.default.attr('boolean'),
    title: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string')
  });
  _exports.default = _default;
});