define("give-public/models/searchdetail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pk: _emberData.default.attr('string'),
    fund_id: _emberData.default.attr('string'),
    fund_drive_id: _emberData.default.attr('number'),
    selected: _emberData.default.attr('boolean'),
    is_athletics_fund: _emberData.default.attr('boolean'),
    is_athletics_benefits_fund: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    amount: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string'),
    description_long: _emberData.default.attr('string'),
    instance: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    cleaned_path: Ember.computed('path', function () {
      return this.path.replace(/[^\w\s]/gi, '');
    })
  });
  _exports.default = _default;
});