define("give-public/components/cart-content-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    value: null,
    isInvalid: true,
    isDirty: false,
    isLess: true,
    store: Ember.inject.service(),
    pledgecart: Ember.inject.service(),
    payrollcart: Ember.inject.service(),
    classNameBindings: ['isInvalid', 'isDirty'],
    isMore: Ember.computed('cart.total', function () {
      return this.cart.total >= 100000;
    }),
    init: function init() {
      this._super.apply(this, arguments);
      var inputValue = this.get('value');
      if (inputValue && (inputValue !== '' || inputValue > 0)) {
        if (inputValue == 0) {
          this.set('value', '$0.00');
          this.set('isLess', true);
        } else {
          var amount = 0;
          amount = this.processInputData(inputValue, amount);
          this.updateState(amount);
        }
        this.set('isDirty', true);
      } else {
        var _amount = '';
        this.set('value', _amount);
        this.set('isDirty', false);
      }
    },
    formatCurrency: function formatCurrency(num) {
      num = +num;
      if (isNaN(num)) {
        return '';
      }
      num = num.toFixed(2);
      return "$".concat(num.replace(/\B(?=(\d{3})+(?!\d)(?=\.))/g, ','));
    },
    processInputData: function processInputData(inputValue, amount) {
      var outputValue = '';
      if (inputValue && inputValue !== '') {
        if (inputValue.substring(0, 1) === '$') {
          amount = parseFloat(inputValue.substring(1).replace(/,/g, '')).toFixed(2);
        } else {
          amount = parseFloat(inputValue.replace(/,/g, '')).toFixed(2);
        }
        outputValue = this.formatCurrency(amount);
      } else {
        outputValue = '$0.00';
      }
      this.set('value', outputValue);
      return amount;
    },
    updateState: function updateState(amount) {
      this.set('isDirty', true);
      if (this.caller !== 'payroll') {
        if (amount >= 10) {
          this.set('isLess', false);
          if (this.cart.total >= 100000 || this.isMore) {
            this.set('isMore', true);
            this.set('isInvalid', true);
          } else {
            this.set('isMore', false);
            this.set('isInvalid', false);
          }
        } else {
          this.set('isLess', true);
          this.set('isInvalid', true);
        }
      }
    },
    updateDataStore: function updateDataStore(item) {
      var fund = this.get('store').peekRecord('fund', item.pk);
      if (fund) {
        fund.set('isLess', this.get('isLess'));
        fund.set('isMore', this.get('isMore'));
        fund.set('isInvalid', this.get('isInvalid'));
        fund.set('isDirty', this.get('isDirty'));
      }
    },
    actions: {
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // comma
          key === 188 ||
          // period
          key === 190 ||
          // decimal point
          key === 110 ||
          // $ sign
          // key === 52 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      updateItemValue: function updateItemValue() {} /*event*/,
      validateAmount: function validateAmount(event) {
        this.set('isDirty', true);
        var inputValue = event.target.value;
        var amount = 0;
        amount = this.processInputData(inputValue, amount);
        this.updateState(amount);

        // let item = this.get('item');
        // this.updateDataStore(item);
      },
      updateCartTotal: function updateCartTotal() {
        var inputValue = this.get('value');
        var amount = 0;
        // this.processInputData(inputValue, amount);
        if (inputValue.substring(0, 1) === '$') {
          amount = parseFloat(inputValue.substring(1).replace(/,/g, '')).toFixed(2);
        } else {
          amount = parseFloat(inputValue.replace(/,/g, '')).toFixed(2);
        }
        this.updateState(amount);
        if (this.caller === 'pledge') {
          this.pledgecart.updateCartTotal();
        } else if (this.caller === 'payroll') {
          this.payrollcart.updateCartTotal();
        } else {
          this.cart.updateCartTotal();
        }
        // this.updateDataStore(item);
      }
    }
  });
  _exports.default = _default;
});