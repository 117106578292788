define("give-public/services/cart", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    items: null,
    total: null,
    localStorage: null,
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    recurringMonth: null,
    recurringYear: null,
    recurringYearInvalid: false,
    recurringMonthInvalid: false,
    grandtotal: null,
    frequency: null,
    isRecurring: false,
    isUntilDate: false,
    is_installments_exceed: false,
    hasAthleticsSection: false,
    recurringTimes: null,
    gift_id: null,
    counter: Ember.computed.alias('items.length'),
    // use init in order to avoid leaking state in components
    init: function init() {
      this._super.apply(this, arguments);
      this.set('items', []);
      this.set('hasAthleticsSection', false);
      this.set('total', 0);
      this.set('grandtotal', 0);
      this.set('isRecurring', false);
      this.set('frequency', 'monthly');
      this.set('isUntilDate', false);
      this.set('recurringYear', '');
      this.set('recurringMonth', '');
      this.set('recurringTimes', 0);
      this.set('recurringYearInvalid', false);
      this.set('recurringMonthInvalid', false);
      this.set('is_installments_exceed', false);
      function lsTest() {
        var test = 'test';
        try {
          localStorage.setItem(test, test);
          localStorage.removeItem(test);
          return true;
        } catch (e) {
          return false;
        }
      }
      if (lsTest() === true) {
        this.set('localStorage', true);
      } else {
        this.set('localStorage', false);
      }
    },
    isAdded: function isAdded(item) {
      var hasItem = false;
      // don't allow duplicate cart items
      hasItem = this.get('items').find(function (basketItem) {
        return item.pk === basketItem.pk;
      });
      if (hasItem) {
        return true;
      }
      return false;
    },
    _get: function _get(item) {
      var current_item = this.get('items').find(function (basketItem) {
        return item.pk === basketItem.pk;
      });
      if (current_item) {
        return current_item;
      }
      return null;
    },
    add: function add(item) {
      var hasItem = false;
      // don't allow duplicate cart items
      hasItem = this.get('items').find(function (basketItem) {
        return item.pk === basketItem.pk;
      });
      var areadetail = this.get('store').peekRecord('areadetail', item.pk);
      var featuredfund = this.get('store').peekRecord('featuredfund', item.pk);
      var fund = this.get('store').peekRecord('fund', item.pk);
      var fundpage = this.get('store').peekRecord('fundpage', item.pk);
      var searchdetail = this.get('store').peekRecord('searchdetail', item.pk);
      var tagdetail = this.get('store').peekRecord('tagdetail', item.pk);
      var from = 'fundsearch';
      if (fund) {
        fund.set('selected', true);
        fund.set('amount', item.amount);
      }
      if (featuredfund) {
        featuredfund.set('selected', true);
        featuredfund.set('amount', item.amount);
        from = 'featuredfund';
      }
      if (areadetail) {
        areadetail.set('selected', true);
        areadetail.set('amount', item.amount);
        from = 'areadetail';
      }
      if (fundpage) {
        fundpage.set('selected', true);
        fundpage.set('amount', item.amount);
        from = 'fund';
      }
      if (searchdetail) {
        searchdetail.set('selected', true);
        searchdetail.set('amount', item.amount);
        from = 'searchdetail';
      }
      if (tagdetail) {
        tagdetail.set('selected', true);
        tagdetail.set('amount', item.amount);
        from = 'tagdetail';
      }
      if (!hasItem) {
        var returnedItem = JSON.parse(JSON.stringify(item));
        this.items.pushObject(returnedItem);
        this.updateCartTotal();
      }
      // check the basket for athletics funds after it's in the basket
      this.set('hasAthleticsSection', this.showAthleticsBenefitsSubform());
    },
    remove: function remove(item, from) {
      var hasItem = false;
      hasItem = this.get('items').find(function (basketItem) {
        return item.pk === basketItem.pk;
      });
      if (hasItem) {
        this.resetDataStore(item);
        this.items.removeObject(item);
        this.set('hasAthleticsSection', this.showAthleticsBenefitsSubform());
        this.updateCartTotal();
      }
    },
    empty: function empty() {
      this.set('items', []);
      this.set('total', 0);
      this.set('hasAthleticsSection', false);
      this._dumpToLocalStorage();
    },
    resetDataStore: function resetDataStore(item) {
      var fund = this.get('store').peekRecord('searchdetail', item.pk);
      if (fund) {
        fund.set('amount', '');
        fund.set('selected', false);
      }
      var featuredfund = this.get('store').peekRecord('featuredfund', item.pk);
      if (featuredfund) {
        featuredfund.set('amount', '');
        featuredfund.set('selected', false);
      }
      var fundpage = this.get('store').peekRecord('fundpage', item.pk);
      if (fundpage) {
        fundpage.set('selected', false);
      }
    },
    calculateTotal: function calculateTotal() {
      var total = this.items.reduce(function (accumulator, item) {
        var inputValue = item.amount;
        var amount = 0;
        if (inputValue) {
          if (inputValue.substring(0, 1) === '$') {
            amount = parseFloat(inputValue.substring(1).replace(/,/g, ''));
          } else {
            amount = parseFloat(inputValue.replace(/,/g, ''));
          }
        }
        if (isNaN(amount)) {
          return accumulator;
        } else {
          return accumulator + amount;
        }
      }, 0);
      this.set('total', total);
    },
    updateCartTotal: function updateCartTotal() {
      this.calculateTotal();
      this.updateCartGrandTotal();
      this._dumpToLocalStorage();
    },
    getInstallments: function getInstallments(frequency, end_date) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/transactions/helpers/recurring-payments/?frequency=").concat(frequency, "&end_date=").concat(end_date);
      return this.get('ajax').request(endpoint);
    },
    getEndDate: function getEndDate(frequency, installments) {
      var endpoint = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/transactions/helpers/recurring-payments/?frequency=").concat(frequency, "&installments=").concat(installments);
      return this.get('ajax').request(endpoint);
    },
    validateTime: function validateTime(validated) {
      var date = new Date();
      var currentYear = date.getFullYear();
      var currentMonth = date.getMonth() + 1;
      var recurringMonth = this.recurringMonth;
      var recurringYear = this.recurringYear;
      if (validated === undefined) {
        validated = true;
      }
      if (this.recurringTimes < 2 || this.frequency === "monthly" && this.recurringTimes > 60 || this.frequency === "quarterly" && this.recurringTimes > 20 || this.frequency === "annually" && this.recurringTimes > 5) {
        validated = false;
        this.set('recurringYearInvalid', true);
        this.set('recurringMonthInvalid', true);
      } else if (currentYear > recurringYear) {
        validated = false;
        this.set('recurringYearInvalid', true);
        this.set('recurringMonthInvalid', true);
      } else if (currentYear == recurringYear && recurringMonth < currentMonth || recurringMonth < 1 || recurringMonth > 12) {
        validated = false;
        this.set('recurringYearInvalid', true);
        this.set('recurringMonthInvalid', true);
      } else if (this.recurringTimes === null) {
        validated = false;
        this.set('recurringYearInvalid', true);
        this.set('recurringMonthInvalid', true);
      } else {
        this.set('recurringYearInvalid', false);
        this.set('recurringMonthInvalid', false);
      }
      return validated;
    },
    updateCartGrandTotal: function updateCartGrandTotal() {
      var _this = this;
      var date = new Date();
      var currentDate = ("0" + date.getDate()).slice(-2);
      var recurringYear = this.get('recurringYear');
      var recurringMonth = ("0" + this.get('recurringMonth')).slice(-2);
      var isRecurring = this.get('isRecurring');
      var frequency = this.get('frequency');
      var isUntilDate = this.get('isUntilDate');
      var recurringTimes = this.get('recurringTimes');
      if (isRecurring && isUntilDate) {
        if (recurringYear && recurringYear !== '' && this.get('recurringMonth') && this.get('recurringMonth') !== '') {
          var end_date = "".concat(recurringYear, "-").concat(recurringMonth, "-").concat(currentDate);
          this.getInstallments(frequency, end_date).then(function (data) {
            if (data['installments'] && Array.isArray(data['installments'])) {
              recurringTimes = data['installments']['length'];
              _this.set('recurringTimes', recurringTimes);
              _this.set('grandtotal', _this.get('total') * recurringTimes);
              if (recurringTimes == 1) {
                _this.set('recurringYearInvalid', true);
                _this.set('recurringMonthInvalid', true);
              } else {
                if (frequency === "monthly" && recurringTimes > 60 || frequency === "quarterly" && recurringTimes > 20 || frequency === "annually" && recurringTimes > 5) {
                  _this.set('recurringYearInvalid', true);
                  _this.set('recurringMonthInvalid', true);
                  _this.set('is_installments_exceed', true);
                } else {
                  _this.set('recurringYearInvalid', false);
                  _this.set('recurringMonthInvalid', false);
                  _this.set('is_installments_exceed', false);
                }
              }
            }
          });
        }
      }
    },
    payLoad: function payLoad() {
      return {
        items: this.get('items'),
        total: this.get('total'),
        hasAthleticsSection: this.get('hasAthleticsSection'),
        isRecurring: this.get('isRecurring')
      };
    },
    pushPayload: function pushPayload(payLoad) {
      this.set('items', payLoad['items']);
      this.set('total', payLoad['total']);
      this.set('hasAthleticsSection', payLoad['hasAthleticsSection']);
    },
    // athletics benefits methods //
    canDeclineAthleticsBenefits: function canDeclineAthleticsBenefits() {
      // returns true if an item in the cart contains this property
      // and that property's value is true
      return this.get('items').find(function (basketItem) {
        return basketItem.is_athletics_benefits_fund;
      });
    },
    showAthleticsBenefitsSubform: function showAthleticsBenefitsSubform(basket_item) {
      var basket = this.items;
      var showSubform = false;
      if (basket) {
        if (this.containsAthleticsBenefitsFund(basket)) {
          showSubform = true;
        }
      } else if (basket_item) {
        showSubform = this.isAthleticsBenefitsFund(basket_item);
      }
      return showSubform;
    },
    containsAthleticsBenefitsFund: function containsAthleticsBenefitsFund(basket) {
      for (var i in basket) {
        var hasAthleticsBenefitsFund = this.isAthleticsBenefitsFund(basket[i]);
        if (hasAthleticsBenefitsFund) {
          return true;
        }
      }
      return false;
    },
    containsAthleticsFund: function containsAthleticsFund(basket) {
      for (var i in basket) {
        var hasAthleticsFund = this.isAthleticsFund(basket[i]);
        if (hasAthleticsFund) {
          return true;
        }
      }
      return false;
    },
    isAthleticsFund: function isAthleticsFund(fund) {
      if (fund.is_athletics_fund) {
        return true;
      }
      return false;
    },
    isAthleticsBenefitsFund: function isAthleticsBenefitsFund(fund) {
      if (fund.is_athletics_benefits_fund) {
        return true;
      }
      return false;
    },
    setAthleticsBenefitsElection: function setAthleticsBenefitsElection(gift, basketItems, hasDeclinedAthleticsBenefits) {
      // allow gift election value from benefits subform
      if (this.containsAthleticsBenefitsFund(basketItems)) {
        if (hasDeclinedAthleticsBenefits) {
          gift.has_declined_athletics_benefits = true;
        } else {
          gift.has_declined_athletics_benefits = false;
        }
        return gift;
      }
      if (this.containsAthleticsFund(basketItems)) {
        gift.has_declined_athletics_benefits = false;
      } else {
        gift.has_declined_athletics_benefits = null;
      }
      return gift;
    },
    _dumpToLocalStorage: function _dumpToLocalStorage() {
      if (this.localStorage) {
        window.localStorage.setItem('cart', JSON.stringify(this.payLoad()));
      }
    }
  });
  _exports.default = _default;
});