define("give-public/components/email-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    validation: Ember.inject.service(),
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      validateField: function validateField(event) {
        this.set('isDirty', true);
        var fieldValue = event.target.value;
        if (fieldValue && fieldValue.trim() !== '' && this.validation.validateEmail(fieldValue)) {
          this.set('isInvalid', false);
        } else {
          this.set('isInvalid', true);
        }
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});