define("give-public/templates/components/tooltip-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VjOP7nKx",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[9],[0,\"Thank you for your generosity! Our giving site cannot process gifts above $100,000. Please contact our Gift Services help desk by phone at 510.643.9789 or by email at \"],[7,\"a\"],[11,\"class\",\"underline_dots\"],[11,\"href\",\"mailto:gifthelp@berkeley.edu\"],[9],[0,\"gifthelp@berkeley.edu\"],[10],[0,\".\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/tooltip-more.hbs"
    }
  });
  _exports.default = _default;
});