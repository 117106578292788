define("give-public/components/basket-summary", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    pledgecart: Ember.inject.service(),
    utilities: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      var fields = [];
      if (this.isValidGift && this.response) {
        if (this.response.context) {
          if (this.response.context.saform && this.response.context.saform.transaction_type) {
            if (this.response.context.saform.transaction_type.includes('sale')) {
              this.set('url', "".concat(_environment.default.APP.CYBER_SOURCE));
            } else {
              this.set('url', "".concat(_environment.default.APP.CYBER_SOURCE_RECURRING));
            }
          }
          if (this.response.context.gift_detail && this.response.context.gift_detail.basket && this.response.context.gift_detail.basket.items) {
            var items = this.response.context.gift_detail.basket.items;
            var basketItems = items.map(function (item) {
              if (item.fund && item.fund.title && item.fund.fund_id) {
                Ember.set(item, 'title', item.fund.title);
                item.pk = item.fund.fund_id;
                item.fund_id = item.fund.fund_id;
                item.id = item.fund.fund_id;
              }
              return item;
            });
            this.set('basketItems', basketItems);
            window.localStorage.setItem('gift_id', this.response.context.gift_detail.id);
          }
          if (this.response.context.saform) {
            var saform = this.response.context.saform;
            for (var key in saform) {
              var field = {};
              field['name'] = key;
              field['value'] = saform[key];
              fields.push(field);
            }
            this.set('fields', fields);
          }
        }
      }
    },
    actions: {
      goToFund: function goToFund(item) {
        if (item.pk || item.fund && item.fund.fund_id) {
          var fund_id = item.pk ? item.pk : item.fund.fund_id;
          this.router.transitionTo('fund', fund_id);
        }
      },
      goTo: function goTo(step) {
        this.sendAction('goTo', step);
      }
    }
  });
  _exports.default = _default;
});