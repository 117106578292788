define("give-public/templates/fundpage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1R5T9AHY",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-container\"],[9],[0,\"\\n  \"],[7,\"main\"],[11,\"class\",\"page-container isSideBarShown\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"main-content-container\"],[9],[0,\"\\n      \"],[1,[29,\"page-header\",null,[[\"toggleModal\",\"funds\"],[\"toggleModal\",[25,[\"funds\"]]]]],false],[0,\"\\n\\n      \"],[7,\"h1\"],[9],[1,[25,[\"fund\",\"name\"]],false],[10],[0,\"\\n\\n      \"],[1,[23,\"page-footer\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[25,[\"isShowingModal\"]]],null,{\"statements\":[[4,\"modal-dialog\",null,[[\"onClose\",\"targetAttachment\",\"translucentOverlay\",\"hasOverlay\"],[[29,\"action\",[[24,0,[]],\"toggleModal\"],null],\"center\",true,true]],{\"statements\":[[0,\"        \"],[1,[29,\"side-bar\",null,[[\"class\",\"toggleModal\",\"featuredfunds\"],[\"sidebar-container\",\"toggleModal\",[25,[\"featuredfunds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[29,\"side-bar\",null,[[\"class\",\"featuredfunds\"],[\"sidebar-container\",[25,[\"featuredfunds\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/fundpage.hbs"
    }
  });
  _exports.default = _default;
});