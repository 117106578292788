define("give-public/models/featuredfund", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pk: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    is_published: _emberData.default.attr('boolean'),
    is_athletics_fund: _emberData.default.attr('boolean'),
    is_athletics_benefits_fund: _emberData.default.attr('boolean'),
    name: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    is_active: _emberData.default.attr('boolean'),
    amount: _emberData.default.attr('string'),
    terms: _emberData.default.attr(),
    created_on: _emberData.default.attr(),
    mofified_on: _emberData.default.attr()
  });
  _exports.default = _default;
});