define("give-public/components/cart-contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
    },
    updateRelatedFunds: function updateRelatedFunds(funds, item, isFundDrive) {
      funds.map(function (fund) {
        if (fund.related_fund_pages && Array.isArray(fund.related_fund_pages) && fund.related_fund_pages.length > 0) {
          var related_fund_pages = fund.related_fund_pages;
          var converted_related_fund_pages = related_fund_pages.map(function (fund_page) {
            var converted_fund = JSON.parse(JSON.stringify(fund_page));
            if (converted_fund.pk === item.pk) {
              converted_fund.selected = false;
            }
            return converted_fund;
          });
          if (isFundDrive && fund.related_fund_pages.length === 1) {
            fund.set('selected', false);
          }
          fund.set('related_fund_pages', converted_related_fund_pages);
        }
        return fund;
      });
    },
    actions: {
      remove: function remove(item) {
        this.cart.remove(item, 'sidebar');
        var areadetail = this.get('store').peekRecord('areadetail', item.pk);
        var featuredfund = this.get('store').peekRecord('featuredfund', item.pk);
        var fund = this.get('store').peekRecord('fund', item.pk);
        var fundpage = this.get('store').peekRecord('fundpage', item.pk);
        var searchdetail = this.get('store').peekRecord('searchdetail', item.pk);
        var searchdetailcopy = this.get('store').peekRecord('searchdetailcopy', item.pk);
        var tagdetail = this.get('store').peekRecord('tagdetail', item.pk);
        if (fund) {
          fund.set('selected', false);
          fund.set('amount', 0);
        }
        if (featuredfund) {
          featuredfund.set('selected', false);
          featuredfund.set('amount', 0);
        }
        if (areadetail) {
          areadetail.set('selected', false);
          areadetail.set('amount', 0);
        }
        if (fundpage) {
          fundpage.set('selected', false);
          fundpage.set('amount', 0);
        }
        if (searchdetail) {
          searchdetail.set('selected', false);
          searchdetail.set('amount', 0);
        }
        if (searchdetailcopy) {
          searchdetailcopy.set('selected', false);
          searchdetailcopy.set('amount', 0);
        }
        if (tagdetail) {
          tagdetail.set('selected', false);
          tagdetail.set('amount', 0);
        }
        var funds = this.get('store').peekAll('fundpage');
        this.updateRelatedFunds(funds, item);
        var funddrives = this.get('store').peekAll('funddrive');
        this.updateRelatedFunds(funddrives, item, true);
        return false;
      },
      updateCartTotal: function updateCartTotal(item) {
        var amount = parseFloat(item.amount);
        var fund = this.get('store').peekRecord('fund', item.pk);
        var featuredfund = this.get('store').peekRecord('featuredfund', item.pk);
        if (fund || featuredfund) {
          this.cart.updateCartTotal();
        }
        if (fund) {
          fund.set('amount', amount);
        }
        if (featuredfund) {
          featuredfund.set('amount', amount);
        }
      },
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.pk);
      },
      giveNow: function giveNow() {
        this.router.transitionTo('giftdetails');
      }
    }
  });
  _exports.default = _default;
});