define("give-public/controllers/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isShowingModal: false,
    isSideBarShown: false,
    tagName: 'main',
    classNames: ['page-container'],
    classNameBindings: ['show:isSideBarShown']
  });
  _exports.default = _default;
});