define("give-public/controllers/faq", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    isShowingModal: false,
    querystr: window.location.search,
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    faqContent: Ember.computed.filterBy('model', 'path', '/faq'),
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      selectFund: function selectFund(result) {
        this.transitionToRoute('fund', result);
      }
    }
  });
  _exports.default = _default;
});