define("give-public/components/side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super();
      var suggestedfunds = this.store.peekAll('suggestedfund');
      if (suggestedfunds.length < 1) {
        suggestedfunds = this.store.findAll('suggestedfund');
      }
      this.set('suggestedfunds', suggestedfunds);
    },
    actions: {
      closeModal: function closeModal() {
        this.sendAction('toggleModal');
      },
      goToFund: function goToFund(fund) {
        this.router.transitionTo('fund', fund.id);
        this.send('closeModal');
      }
    }
  });
  _exports.default = _default;
});