define("give-public/instance-initializers/cart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(appInstance) {
    var payload;
    var _appInstance$containe = appInstance.container,
      container = _appInstance$containe === void 0 ? appInstance : _appInstance$containe;
    var cart = container.lookup('service:cart');
    var cartString = window.localStorage.getItem('cart');
    if (cartString) {
      payload = JSON.parse(cartString);
    }
    if (payload !== undefined) {
      cart.pushPayload(payload);
    }
    container.register('cart:main', cart, {
      instantiate: false
    });
    container.inject('controller', 'cart', 'cart:main');
    container.inject('component', 'cart', 'cart:main');
  }
  var _default = {
    initialize: initialize,
    name: 'cart'
  };
  _exports.default = _default;
});