define("give-public/controllers/vip", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    hasSidebar: false,
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    isShowingModal: false,
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    show: Ember.computed('isSideBarShown', function () {
      return this.get('isSideBarShown');
    }),
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      hideSideBar: function hideSideBar() {
        this.set('isSideBarShown', true);
      },
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      }
    }
  });
  _exports.default = _default;
});