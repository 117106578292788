define("give-public/components/month-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
      var value = this.get('value');
      if (value) {
        this.validateMonth(value);
      }
    },
    validateMonth: function validateMonth(monthStr) {
      this.set('isDirty', true);
      var date = new Date();
      var currentMonth = date.getMonth() + 1;
      var currentYear = date.getFullYear();
      var isValidMonth = true;
      var recurringYear = parseInt(this.cart.get('recurringYear'));
      var month = parseInt(monthStr);
      if (recurringYear) {
        if (currentYear > recurringYear) {
          isValidMonth = false;
        }
        if (currentYear == recurringYear && month < currentMonth) {
          isValidMonth = false;
        }
      }
      if (!month || month === '' || month < 1 || month > 12) {
        isValidMonth = false;
      }
      if (!isValidMonth) {
        this.set('isInvalid', true);
        this.cart.set('recurringYearInvalid', true);
      } else {
        this.set('isInvalid', false);
      }
      if (month > 0 && month < 10) {
        if (month.toString().substring(0, 1) !== '0') {
          month = "0".concat(month);
        }
        this.set('value', month);
      }
      if (this.cart.get('recurringMonthInvalid') || this.cart.get('recurringYearInvalid')) {
        this.set('isInvalid', true);
      }
      return isValidMonth;
    },
    actions: {
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      updateCartMonth: function updateCartMonth(event) {
        var month = event.target.value;
        var name = this.name;
        if (this.validateMonth(month)) {
          this.cart.set('recurringMonth', month);
          this.cart.updateCartGrandTotal();
          this.send('updateAttr', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
        } else {
          this.cart.set('recurringTimes', null);
          this.cart.set('recurringMonth', month);
          this.send('updateAttr', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
        }
        return false;
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});