define("give-public/components/pledge-information-details", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    utilities: Ember.inject.service(),
    pledgecart: Ember.inject.service(),
    billingCountry: 'US',
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      var utilities = this.get('utilities');
      var gift_id = window.localStorage.getItem('pledge_gift_id');
      // let gift_id = 1694;

      if (this.failed && gift_id) {
        utilities.getPledgeTransactionResult(gift_id).then(function (data) {
          _this.setFailedBasketState(data);
          _this.setFailedBillingInfoState(data);
        });
      } else {
        this.setInitialBillingInfoState();
      }
      utilities.getCountries().then(function (data) {
        var countries = [{
          name: 'United States',
          itemValue: _this.billingCountry
        }];
        for (var i = 0; i < data.results.length; i++) {
          var result = data.results[i];
          result.itemValue = result.iso2;
          countries.push(result);
        }
        _this.set('countries', countries);
        _this.set('billingCountry', _this.billingCountry);
      }).then(function () {
        utilities.getRegions(_this.billingCountry).then(function (results) {
          var regions = results.map(function (result) {
            if (result.code2) {
              result.itemValue = result.code2;
            } else {
              result.itemValue = result.name;
            }
            return result;
          });
          _this.set('billingStates', regions);
        });
      });
    },
    getCountries: function getCountries() {
      var _this2 = this;
      var utilities = this.get('utilities');
      utilities.getCountries().then(function (data) {
        var countries = data.results.map(function (result) {
          result.itemValue = result.iso2;
          return result;
        });
        countries.push({
          name: 'United States',
          itemValue: 'US'
        });
        _this2.set('countries', countries);
      });
    },
    getRegions: function getRegions(name, value) {
      var _this3 = this;
      var utilities = this.get('utilities');
      utilities.getRegions(value).then(function (data) {
        var regions = data.map(function (result) {
          if (result.code2) {
            result.itemValue = result.code2;
          } else {
            if (result.name) {
              result.itemValue = result.name;
            } else {
              result.itemValue = value;
              result.name = value;
            }
          }
          return result;
        });
        if (name === 'billingCountry') {
          _this3.set('billingStates', regions);
          if (value === 'US') {
            _this3.set('billingStatePlaceholder', 'State');
            _this3.set('billingZipPlaceholder', 'ZIP');
          } else {
            _this3.set('billingStatePlaceholder', 'Province');
            _this3.set('billingZipPlaceholder', 'Postal Code');
          }
        }
      });
    },
    setFailedBasketState: function setFailedBasketState(data) {
      var items = data.basket.items.map(function (item) {
        var cart_item = {};
        cart_item.amount = item.amount;
        cart_item.pk = item.non_give_fund;
        cart_item.id = item.non_give_fund;
        cart_item.title = item.name;
        cart_item.name = item.name;
        return cart_item;
      });
      this.pledgecart.set('items', items);
      this.pledgecart.updateCartTotal();
    },
    setFailedBillingInfoState: function setFailedBillingInfoState(data) {
      this.set('isCompanyGift', data.donor.address.is_business);
      this.set('isNotCall', data.donor.phone.do_not_call);
      this.set('billingFirstName', data.donor.forename);
      this.set('billingMiddleName', data.donor.middlename);
      this.set('billingLastName', data.donor.surname);
      this.set('billingCountry', data.donor.address.country);
      this.set('billingAddress', data.donor.address.line1);
      this.set('billingAddressLine2', data.donor.address.line2);
      this.set('billingCity', data.donor.address.city);
      this.set('billingStatePlaceholder', 'State');
      this.getRegions('billingCountry', data.donor.address.country);
      this.set('billingState', data.donor.address.state);
      this.set('billingZip', data.donor.address.postal_code);
      this.set('billingEmail', data.donor.email_address.email_address);
      this.set('billingPhone', data.donor.phone.phone_number);
      this.set('phoneType', data.donor.phone.type);
    },
    setInitialBillingInfoState: function setInitialBillingInfoState() {
      this.set('isCompanyGift', false);
      this.set('isNotCall', false);
      this.set('billingFirstName', '');
      this.set('billingFirstNameDirty', false);
      this.set('billingFirstNameInvalid', true);
      this.set('billingMiddleName', '');
      this.set('billingLastName', '');
      this.set('billingLastNameDirty', false);
      this.set('billingLastNameInvalid', true);
      this.set('billingAddress', '');
      this.set('billingAddressDirty', false);
      this.set('billingAddressInvalid', true);
      this.set('billingAddressLine2', '');
      this.set('billingCity', '');
      this.set('billingCityDirty', false);
      this.set('billingCityInvalid', true);
      this.set('billingStatePlaceholder', 'State');
      this.set('billingState', '');
      this.set('billingStateDirty', false);
      this.set('billingStateInvalid', true);
      this.set('billingZip', '');
      this.set('billingZipDirty', false);
      this.set('billingZipInvalid', true);
      this.set('billingZipPlaceholder', 'ZIP');
      this.set('billingEmail', '');
      this.set('billingEmailDirty', false);
      this.set('billingEmailInvalid', true);
      this.set('billingPhone', '');
      this.set('billingPhoneDirty', false);
      this.set('billingPhoneInvalid', true);
      this.set('phoneType', 'mobile');
    },
    validateState: function validateState() {
      var validated = true;
      this.setDirtyState();
      var items = this.pledgecart.get('items');
      if (!items || items.length <= 0) {
        validated = false;
      }
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var inputValue = item.amount;
        if (!inputValue || inputValue.trim() === '') {
          validated = false;
        } else {
          var amount = this.processFundAmount(inputValue);
          if (item['isInvalid'] || amount < 10 || amount > 100000) {
            validated = false;
          }
        }
      }
      if (this.get('billingFirstName').trim() === '') {
        validated = false;
        this.set('billingFirstNameInvalid', true);
      } else {
        this.set('billingFirstNameInvalid', false);
      }
      if (this.get('billingLastName').trim() === '') {
        validated = false;
        this.set('billingLastNameInvalid', true);
      } else {
        this.set('billingLastNameInvalid', false);
      }
      if (this.get('billingState').trim() === '') {
        validated = false;
        this.set('billingStateInvalid', true);
      } else {
        this.set('billingStateInvalid', false);
      }
      if (this.get('billingAddress').trim() === '') {
        validated = false;
        this.set('billingAddressInvalid', true);
      } else {
        this.set('billingAddressInvalid', false);
      }
      if (this.get('billingCity').trim() === '') {
        validated = false;
        this.set('billingCityInvalid', true);
      } else {
        this.set('billingCityInvalid', false);
      }
      if (this.get('billingEmail').trim() === '') {
        validated = false;
        this.set('billingEmailInvalid', true);
      } else {
        this.set('billingEmailInvalid', false);
      }
      if (this.get('billingPhone').trim() === '') {
        validated = false;
        this.set('billingPhoneInvalid', true);
      } else {
        this.set('billingPhoneInvalid', false);
      }
      if (this.get('billingZip').trim() === '') {
        validated = false;
        this.set('billingZipInvalid', true);
      } else {
        this.set('billingZipInvalid', false);
      }
      return validated;
    },
    processFundAmount: function processFundAmount(inputValue) {
      var amount;
      if (inputValue.substring(0, 1) === '$') {
        amount = parseFloat(inputValue.substring(1).replace(/,/g, '')).toFixed(2);
      } else {
        amount = parseFloat(inputValue.replace(/,/g, '')).toFixed(2);
      }
      return amount;
    },
    setShoppingCartDirtyState: function setShoppingCartDirtyState() {
      var _this4 = this;
      var items = JSON.parse(JSON.stringify(this.pledgecart.get('items')));
      var updatedItems = items.map(function (fund) {
        var inputValue = fund.amount;
        var amount = 0;
        if (inputValue != 0) {
          amount = _this4.processFundAmount(inputValue);
        }
        if (amount >= 10) {
          fund.isLess = false;
          fund.isInvalid = false;
          fund.isMore = false;
          if (amount >= 100000) {
            fund.isMore = true;
            fund.isInvalid = true;
            fund.isDirty = true;
          }
        } else {
          fund.isLess = true;
          fund.isInvalid = true;
          fund.isMore = false;
          fund.isDirty = true;
        }
        fund.isDirty = true;
        return fund;
      });
      this.pledgecart.set('items', updatedItems);
    },
    setDirtyState: function setDirtyState() {
      this.setShoppingCartDirtyState();
      this.set('billingFirstNameDirty', true);
      this.set('billingLastNameDirty', true);
      this.set('billingStateDirty', true);
      this.set('billingAddressDirty', true);
      this.set('billingCityDirty', true);
      this.set('billingZipDirty', true);
      this.set('billingEmailDirty', true);
      this.set('billingPhoneDirty', true);
    },
    actions: {
      toggleCompanyGift: function toggleCompanyGift() {
        this.toggleProperty('isCompanyGift');
      },
      toggleNotCall: function toggleNotCall() {
        this.toggleProperty('isNotCall');
      },
      updateFieldValue: function updateFieldValue(name, value) {
        var self = this;
        self.set("".concat(name), value);
        if (name === 'billingCountry') {
          if (value !== '') {
            self.getRegions(name, value);
          }
        }
      },
      submitGift: function submitGift() {
        if (this.validateState() && this.pledgecart.total < 100000) {
          var gift = {
            grand_total: this.pledgecart.get('total'),
            is_anonymous: false,
            is_joint: true,
            is_pledge: true,
            has_declined_athletics_benefits: null,
            bill_to: {
              forename: this.get('billingFirstName'),
              middlename: this.get('billingMiddleName'),
              surname: this.get('billingLastName'),
              address: {
                country: this.get('billingCountry'),
                line1: this.get('billingAddress'),
                line2: this.get('billingAddressLine2'),
                city: this.get('billingCity'),
                state: this.get('billingState'),
                postal_code: this.get('billingZip'),
                is_business: this.get('isCompanyGift')
              },
              contact_info: {
                email: this.get('billingEmail'),
                phone_number: this.get('billingPhone'),
                type: this.get('phoneType'),
                do_not_call: this.get('isNotCall')
              }
            }
          };
          var items = JSON.parse(JSON.stringify(this.pledgecart.get('items')));
          var basketItems = items.map(function (item) {
            var amount = item.amount;
            var itemAmount = 0;
            if (typeof amount == 'string') {
              if (amount.substring(0, 1) === '$') {
                itemAmount = parseFloat(amount.substring(1).replace(/,/g, '')).toFixed(2);
              } else {
                itemAmount = parseFloat(amount.replace(/,/g, '')).toFixed(2);
              }
              item.amount = itemAmount;
            }
            return item;
          });
          gift['items'] = basketItems;
          var submit = this.get('utilities');
          submit.createPledge(gift).then(function (response) {
            var form = document.createElement("form");
            var url;
            if (response.context && response.context.saform && response.context.saform.transaction_type) {
              if (response.context.saform.transaction_type.includes('sale')) {
                url = "".concat(_environment.default.APP.CYBER_SOURCE);
              } else {
                url = "".concat(_environment.default.APP.CYBER_SOURCE_RECURRING);
              }
            }
            form.method = "POST";
            form.action = url;
            if (response.context && response.context.gift_detail && response.context.gift_detail.id) {
              window.localStorage.setItem('pledge_gift_id', response.context.gift_detail.id);
            }
            if (response.context && response.context.saform) {
              var saform = response.context.saform;
              for (var key in saform) {
                var element = document.createElement("input");
                element['name'] = key;
                element['value'] = saform[key];
                form.appendChild(element);
              }
            }
            document.body.appendChild(form);
            form.submit();
          }).catch(function () {});
        }
      }
    }
  });
  _exports.default = _default;
});