define("give-public/components/required-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      validateField: function validateField(event) {
        this.set('isDirty', true);
        var fieldValue = event.target.value;
        var name = event.target.name;
        if (fieldValue && fieldValue.trim() !== '') {
          this.set('isInvalid', false);
        } else {
          this.set('isInvalid', true);
        }
        this.set('value', fieldValue.trim());
        this.send('updateAttr', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});