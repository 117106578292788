define("give-public/templates/components/tagpill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LYdC5Bxo",
    "block": "{\"symbols\":[\"tag\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"tag-pills\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"tags\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"pill\"],[9],[0,\"\\n      \"],[5,\"link-to\",[],[[\"@route\",\"@query\"],[\"tagdetails\",[29,\"hash\",null,[[\"slug\",\"name\"],[[24,1,[\"slug\"]],[24,1,[\"name\"]]]]]]],{\"statements\":[[1,[24,1,[\"name\"]],false]],\"parameters\":[]}],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/tagpill.hbs"
    }
  });
  _exports.default = _default;
});