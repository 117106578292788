define("give-public/components/home-contents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    biggivePhase: Ember.inject.service(),
    context: Ember.computed('staticpages.@each', function () {
      var content = this.staticpages.filter(function (item) {
        if (item.path && item.path === '/home') {
          return item;
        }
      });
      return content.firstObject;
    }),
    campaignTakeover: Ember.computed('takeovers.@each', function () {
      try {
        var t = this.takeovers.filter(function (item) {
          if (item.name === 'HOMEPAGE_SUB_HERO_TAKEOVER') {
            return item;
          }
        });
        return t.firstObject;
      } catch (error) {
        return error;
      }
    }),
    livePhaseContext: Ember.computed('staticpages.@each', function () {
      var content = this.staticpages.filter(function (item) {
        if (item.path && item.path === '/bghome') {
          return item;
        }
      });
      return content.firstObject;
    }),
    init: function init() {
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});