define("give-public/routes/areadetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      slug: {
        replace: false,
        refreshModel: true
      }
    },
    model: function model(params) {
      return this.store.query('areadetail', {
        slug: params.slug
      }).then(function (res) {
        return res;
      });
    },
    afterModel: function afterModel(model) {
      var slug = model.query.slug.toLowerCase().split('-').map(function (s) {
        return s.charAt(0).toUpperCase() + s.substring(1);
      }).join(' ');
      document.title = "".concat(slug, " - Give to Berkeley");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('areadetails', model);
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});