define("give-public/templates/components/email-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SCE1D4gk",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"input\",null,[[\"autocomplete\",\"type\",\"value\",\"name\",\"focusOut\",\"updateAttr\",\"placeholder\",\"class\"],[\"off\",\"email\",[25,[\"value\"]],[25,[\"name\"]],[29,\"action\",[[24,0,[]],\"validateField\"],null],[29,\"action\",[[24,0,[]],\"updateAttr\",[25,[\"name\"]],[25,[\"value\"]],[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null],[25,[\"placeholder\"]],[25,[\"style\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"error-message\"],[9],[0,\"Please enter a valid email address.\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/email-input.hbs"
    }
  });
  _exports.default = _default;
});