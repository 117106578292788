define("give-public/templates/components/addtobasket-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RwZYWQLV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"card text-center\"],[9],[0,\"\\n\"],[4,\"resize-text\",null,[[\"minSize\",\"maxSize\"],[13,24]],{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"from\"]],\"fundpage\"],null]],null,{\"statements\":[[4,\"if\",[[24,0,[\"fund\",\"is_published\"]]],null,{\"statements\":[[0,\"        \"],[7,\"a\"],[11,\"class\",\"title\"],[11,\"role\",\"button\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"goToFund\",[24,0,[\"fund\"]]],null]],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"title\"],[9],[1,[23,\"title\"],false],[10],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[10],[0,\"\\n\"],[5,\"addtobasket\",[[13,\"class\",\"link-action\"]],[[\"@fund\",\"@default_gift_value\",\"@default_gift_frequency\",\"@default_gift_installments\",\"@selected\"],[[24,0,[\"fund\"]],[24,0,[\"default_gift_value\"]],[24,0,[\"default_gift_frequency\"]],[24,0,[\"default_gift_installments\"]],[24,0,[\"fund\",\"selected\"]]]]]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/addtobasket-card.hbs"
    }
  });
  _exports.default = _default;
});