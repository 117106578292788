define("give-public/routes/tagdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    queryParams: {
      slug: {
        replace: false,
        refreshModel: true
      },
      name: {
        replace: false,
        refreshModel: true
      }
    },
    model: function model(params) {
      var tag_id = params.slug.replace(/'/g, '');
      return this.store.query('tagdetail', {
        tagId: tag_id.replace(/\s/g, '-')
      }).then(function (res) {
        res.tag_name = params.name;
        return res;
      });
    },
    afterModel: function afterModel(model) {
      document.title = "Tag for \"".concat(model.query.tagId, "\" - Give to Berkeley");
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('tagdetails', model);
    },
    actions: {
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});