define("give-public/components/basket-honor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    countries: null,
    designationNote: 'This designation will apply to all funds you are supporting today.',
    init: function init() {
      this._super.apply(this, arguments);
      if (this.countries && Array.isArray(this.countries) && this.countries.length > 0) {
        for (var i = 0; i < this.countries.length; i++) {
          if (this.countries[i]['itemValue'] === this.mailCountry) {
            Ember.set(this.countries[i], 'selected', true);
          } else {
            Ember.set(this.countries[i], 'selected', false);
          }
        }
      }
    },
    actions: {
      honorSwitch: function honorSwitch(honor) {
        this.sendAction('honorSwitch', honor);
      },
      changeMailType: function changeMailType(mail) {
        this.sendAction('changeMailType', mail);
      },
      toggleHonored: function toggleHonored() {
        this.sendAction('toggleHonored');
      },
      toggleMail: function toggleMail() {
        this.sendAction('toggleMail');
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      },
      setSelection: function setSelection(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.send('updateFieldValue', name, value);
      },
      updateFieldValue: function updateFieldValue(name, value) {
        this.sendAction('updateFieldValue', name, value);
      }
    }
  });
  _exports.default = _default;
});