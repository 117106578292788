define("give-public/templates/components/select-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YZK7lhYe",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\"],[12,\"name\",[23,\"name\"]],[12,\"value\",[23,\"value\"]],[12,\"class\",[23,\"style\"]],[12,\"onblur\",[29,\"action\",[[24,0,[]],\"validateSelect\"],null]],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"validateSelect\"],null]],[9],[0,\"\\t\\n\\t\"],[7,\"option\"],[11,\"value\",\"\"],[11,\"class\",\"placeholder\"],[9],[1,[23,\"placeholder\"],false],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"options\"]]],null,{\"statements\":[[0,\"\\t\"],[7,\"option\"],[12,\"value\",[24,1,[\"itemValue\"]]],[12,\"selected\",[24,1,[\"selected\"]]],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\\n\"],[4,\"if\",[[29,\"and\",[[25,[\"isDirty\"]],[25,[\"isInvalid\"]]],null]],null,{\"statements\":[[7,\"div\"],[11,\"class\",\"error-message\"],[9],[0,\"Required\"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/select-dropdown.hbs"
    }
  });
  _exports.default = _default;
});