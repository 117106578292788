define("give-public/components/payroll-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isShowingModal: false,
    utilities: Ember.inject.service(),
    payrollcart: Ember.inject.service(),
    submitButton: 'Submit',
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.setInitialState();
      var utilities = this.get('utilities');
      utilities.getRegions('US').then(function (data) {
        var regions = data.map(function (result) {
          if (result.code2) {
            result.itemValue = result.code2;
          } else {
            result.itemValue = result.name;
          }
          return result;
        });
        _this.set('states', regions);
      });
    },
    setInitialState: function setInitialState() {
      this.set('employeeNumber', this.user.employeeNumber);
      this.set('employeeDepartment', this.user.department);
      this.set('firstName', this.user.berkeleyEduFirstName);
      this.set('firstNameDirty', false);
      this.set('firstNameInvalid', true);
      this.set('middleName', '');
      this.set('lastName', this.user.berkeleyEduLastName);
      this.set('lastNameDirty', false);
      this.set('lastNameInvalid', true);
      this.set('address1', '');
      this.set('address1Dirty', false);
      this.set('address1Invalid', true);
      this.set('address2', '');
      this.set('city', '');
      this.set('cityDirty', false);
      this.set('cityInvalid', true);
      this.set('state', '');
      this.set('stateDirty', false);
      this.set('stateInvalid', true);
      this.set('zip', '');
      this.set('zipDirty', false);
      this.set('zipInvalid', true);
      this.set('email', this.user.berkeleyEduOfficialEmail);
      this.set('emailDirty', false);
      this.set('emailInvalid', true);
      this.set('phone', '');
      this.set('phoneDirty', false);
      this.set('phoneInvalid', true);
      var items = JSON.parse(JSON.stringify(this.payrollcart.get('items')));
      var updatedItems = items.map(function (item) {
        item.amount = 0;
        if (item.uuid === 'other_id') {
          item.name = '';
          item.title = '';
        }
        return item;
      });
      this.payrollcart.set('items', updatedItems);
      this.payrollcart.set('total', 0);
    },
    setShoppingCartDirtyState: function setShoppingCartDirtyState() {
      var items = JSON.parse(JSON.stringify(this.payrollcart.get('items')));
      var updatedItems = items.map(function (fund) {
        fund.isLess = false;
        fund.isInvalid = false;
        fund.isMore = false;
        fund.isDirty = true;
        return fund;
      });
      this.payrollcart.set('items', updatedItems);
    },
    setDirtyState: function setDirtyState() {
      this.setShoppingCartDirtyState();
      this.set('firstNameDirty', true);
      this.set('lastNameDirty', true);
      this.set('address1Dirty', true);
      this.set('cityDirty', true);
      this.set('stateDirty', true);
      this.set('zipDirty', true);
      this.set('emailDirty', true);
      this.set('phoneDirty', true);
    },
    validateState: function validateState() {
      var validated = true;
      this.setDirtyState();
      if (this.get('firstName').trim() === '') {
        validated = false;
        this.set('firstNameInvalid', true);
      } else {
        this.set('firstNameInvalid', false);
      }
      if (this.get('lastName').trim() === '') {
        validated = false;
        this.set('lastNameInvalid', true);
      } else {
        this.set('lastNameInvalid', false);
      }
      if (this.get('address1').trim() === '') {
        validated = false;
        this.set('address1Invalid', true);
      } else {
        this.set('address1Invalid', false);
      }
      if (this.get('city').trim() === '') {
        validated = false;
        this.set('cityInvalid', true);
      } else {
        this.set('cityInvalid', false);
      }
      if (this.get('state').trim() === '') {
        validated = false;
        this.set('stateInvalid', true);
      } else {
        this.set('stateInvalid', false);
      }
      if (this.get('zip').trim() === '') {
        validated = false;
        this.set('zipInvalid', true);
      } else {
        this.set('zipInvalid', false);
      }
      if (this.get('email').trim() === '') {
        validated = false;
        this.set('emailInvalid', true);
      } else {
        this.set('emailInvalid', false);
      }
      if (this.get('phone').trim() === '') {
        validated = false;
        this.set('phoneInvalid', true);
      } else {
        this.set('phoneInvalid', false);
      }
      return validated;
    },
    actions: {
      updateItemName: function updateItemName(event) {
        var value = event.target.value;
        var name = event.target.name;
        if (name === 'otherFund') {
          var items = JSON.parse(JSON.stringify(this.payrollcart.get('items')));
          var updatedItems = items.map(function (item) {
            if (item.uuid === 'other_id') {
              item.name = value;
              item.title = value;
            }
            return item;
          });
          this.payrollcart.set('items', updatedItems);
        }
      },
      updateFieldValue: function updateFieldValue(name, value) {
        this.set("".concat(name), value);
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.set("".concat(name, "Dirty"), isDirty);
        this.set("".concat(name, "Invalid"), isInvalid);
        this.send('updateFieldValue', name, value);
      },
      setSelection: function setSelection(event) {
        var name = event.target.name;
        var value = event.target.value;
        this.send('updateFieldValue', name, value);
      },
      toggleNotCall: function toggleNotCall() {
        this.toggleProperty('isNotCall');
      },
      submitPayrollDeduction: function submitPayrollDeduction() {
        if (this.validateState()) {
          this.send('submit');
        }
      },
      // action to submit notification form
      submit: function submit() {
        var _this2 = this;
        var payroll = {
          is_paid: false,
          employee_number: this.user.employeeNumber,
          employee_department: this.user.department,
          department: this.get('department'),
          bill_to: {
            forename: this.get('firstName'),
            middlename: this.get('middleName'),
            surname: this.get('lastName'),
            address: {
              country: "US",
              line1: this.get('address1'),
              line2: this.get('address2'),
              city: this.get('city'),
              state: this.get('state'),
              postal_code: this.get('zip'),
              is_business: false
            },
            contact_info: {
              email: this.get('email'),
              type: 'mobile',
              do_not_call: false
            }
          }
        };
        var items = JSON.parse(JSON.stringify(this.payrollcart.get('items')));
        var basketItems = items.map(function (item) {
          var amount = item.amount;
          var itemAmount = 0;
          if (typeof amount == 'string') {
            if (amount.substring(0, 1) === '$') {
              itemAmount = parseFloat(amount.substring(1).replace(/,/g, '')).toFixed(2);
            } else {
              itemAmount = parseFloat(amount.replace(/,/g, '')).toFixed(2);
            }
            item.amount = itemAmount;
          }
          return item;
        });
        payroll['items'] = basketItems;
        var phone_number = this.get('phone');
        if (phone_number && phone_number.trim() !== '') {
          payroll['bill_to']['contact_info']['phone_number'] = phone_number;
        }
        var utilities = this.get('utilities');
        utilities.createPayroll(payroll).then(function (data) {
          if (data.created) {
            _this2.setInitialState();
            _this2.set('submitButton', 'Message Sent');
          } else {
            _this2.set('submitButton', 'Message Failed');
          }
        });
      }
    }
  });
  _exports.default = _default;
});