define("give-public/components/turnstile", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    turnstileState: Ember.inject.service('turnstile-state'),
    sitekey: _environment.default.APP.TURNSTILE_SITE_KEY,
    baseUrl: _environment.default.APP.TURNSTILE_BASE_URL,
    // didRender() {
    //   this._super(...arguments);
    //     try {
    //       //window.turnstile.render('cf-turnstile-widget');
    //       this.turnstileState.renderWidget();
    //     } catch (err) {
    //       console.log('Could not render Turnstile. '+err)
    //     }
    // },
    //
    // willDestroyElement() {
    //   this._super(...arguments);
    //   this.turnstileState.incrementDestroyCount();
    // },
    //
    renderTurnstile: function renderTurnstile() {
      var properties = this.getProperties('sitekey');
      var token = null;
      var tokenService = this.turnstileState;
      var parameters = Ember.merge(properties, {
        callback: function callback(token) {
          //console.log(`Challenge Success ${token}`);
          tokenService.addToken(token);
        },
        'expired-callback': this.get('expiredCallback').bind(this)
      });
      var widgetId = window.turnstile.render(this.get('element'), parameters);
      this.set('widgetId', widgetId);
      this.set('ref', this);
      this.renderCallback();
      //console.log('renderTurnstile: '+widgetId);
    },
    resetTurnstile: function resetTurnstile() {
      if (Ember.isPresent(this.get('widgetId'))) {
        window.turnstile.reset(this.get('widgetId'));
      }
      //console.log('resetTurnstile: ---');
    },
    renderCallback: function renderCallback() {
      var action = this.get('onRender');
      if (Ember.isPresent(action)) {
        action();
      }
      //console.log('renderCallback: ---');
    },
    successCallback: function successCallback(turnstileResponse) {
      var action = this.get('onSuccess');
      if (Ember.isPresent(action)) {
        action(turnstileResponse);
      }
      //console.log('successCallback: ---');
    },
    expiredCallback: function expiredCallback() {
      var action = this.get('onExpired');
      if (Ember.isPresent(action)) {
        action();
      } else {
        this.resetTurnstile();
      }
      //console.log('expiredCallback: ---');
    },
    appendScript: function appendScript(src) {
      var scriptTag = document.createElement('script');
      scriptTag.src = src;
      document.body.appendChild(scriptTag);
      //console.log('appendScript: script tag appended');
    },
    // Lifecycle Hooks
    didInsertElement: function didInsertElement() {
      var _this = this;
      this._super.apply(this, arguments);
      window.__ember_turnstile_onload_callback = function () {
        _this.renderTurnstile();
      };
      this.appendScript("".concat(this.baseUrl, "&onload=__ember_turnstile_onload_callback"));
      //console.log('didInsertElement: script tag loaded');
    }
  });
  _exports.default = _default;
});