define("give-public/templates/components/statbox", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VrmWLkwB",
    "block": "{\"symbols\":[],\"statements\":[[7,\"dt\"],[11,\"class\",\"header\"],[9],[1,[23,\"label\"],false],[10],[0,\"\\n\"],[7,\"dd\"],[9],[1,[23,\"value\"],false],[1,[23,\"after\"],false],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/statbox.hbs"
    }
  });
  _exports.default = _default;
});