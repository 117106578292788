define("give-public/components/search-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.initState();
      this.filter('').then(function (results) {
        return _this.set('results', results);
      });
    },
    initState: function initState() {
      this.set('className', ['list-filter']);
      this.set('value', '');
    },
    actions: {
      handleFilterEntry: function handleFilterEntry() {
        var _this2 = this;
        var filterInputValue = this.value;
        var filterAction = this.filter;
        filterAction(filterInputValue).then(function (filterResults) {
          _this2.set('results', filterResults);
        });
      }
    }
  });
  _exports.default = _default;
});