define("give-public/templates/components/index-featured-funds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kShBpEwC",
    "block": "{\"symbols\":[\"featuredfund\"],\"statements\":[[7,\"ul\"],[11,\"class\",\"list-container\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"featuredFunds\"]]],null,{\"statements\":[[0,\"    \"],[7,\"li\"],[11,\"class\",\"featured-fund-container\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"featured-fund text-center\"],[9],[0,\"\\n        \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"title\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"goToFund\",[24,1,[]]],null]],[9],[0,\"\\n\"],[4,\"resize-text\",null,[[\"minSize\",\"maxSize\"],[18,26]],{\"statements\":[[0,\"          \"],[7,\"span\"],[11,\"class\",\"title-text\"],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[10],[0,\"\\n        \"],[5,\"addtobasket\",[[13,\"class\",\"link-action\"]],[[\"@fund\",\"@selected\"],[[24,1,[]],[24,1,[\"selected\"]]]]],[0,\"\\n      \"],[10],[0,\"     \\n    \"],[10],[0,\" \\n\"]],\"parameters\":[1]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/index-featured-funds.hbs"
    }
  });
  _exports.default = _default;
});