define("give-public/controllers/payroll", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    utilities: Ember.inject.service(),
    router: Ember.inject.service(),
    is_authenticated: false,
    user: null,
    // is_authenticated: true,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      var utilities = this.get('utilities');
      utilities.getPayrollAuthenticated().then(function (user) {
        _this.set('user', user);
        _this.set('is_authenticated', true);
      }).catch(function () {
        var login_url = "".concat(_environment.default.APP.HOST, "/payroll/login");
        window.location.replace(login_url);
      });
    }
  });
  _exports.default = _default;
});