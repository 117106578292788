define("give-public/components/basket-atheletics", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      toggleAthleticsBenefitsElection: function toggleAthleticsBenefitsElection() {
        this.sendAction('toggleAthleticsBenefitsElection');
      }
    }
  });
  _exports.default = _default;
});