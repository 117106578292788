define("give-public/controllers/giftdetails", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    hasSidebar: false,
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    actions: {
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      }
    }
  });
  _exports.default = _default;
});