define("give-public/models/funddrive", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    source_fund_drive: _emberData.default.attr('number'),
    title: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    progress: _emberData.default.attr('number'),
    start_on: _emberData.default.attr('string'),
    end_on: _emberData.default.attr('string'),
    achive_on: _emberData.default.attr('string'),
    default_gift_installments: _emberData.default.attr('number'),
    default_gift_frequency: _emberData.default.attr('string'),
    default_gift_value: _emberData.default.attr('string'),
    description_long: _emberData.default.attr('string'),
    description_long_post: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string'),
    description_short_post: _emberData.default.attr('string'),
    monetary_goal: _emberData.default.attr('string'),
    amount_raised: _emberData.default.attr('string'),
    version: _emberData.default.attr('number'),
    selected: _emberData.default.attr('boolean'),
    related_fund_pages: _emberData.default.attr(),
    related_users: _emberData.default.attr(),
    created: _emberData.default.attr(),
    modified: _emberData.default.attr()
  });
  _exports.default = _default;
});