define("give-public/services/payrollcart", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    items: null,
    total: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('total', 0);
      this.set('items', [{
        'uuid': 'W6033',
        'pk': 'W6033',
        'name': 'The Faculty, Staff and Student Fund for Undergraduate Scholarships',
        'title': 'The Faculty, Staff and Student Fund for Undergraduate Scholarships',
        'amount': 0,
        'isLess': false,
        'isMore': false,
        'isDirty': false,
        'isInvalid': false
      }, {
        'uuid': 'W6032',
        'pk': 'W6032',
        'name': 'The Faculty, Staff and Student Fund for Graduate Fellowships',
        'title': 'The Faculty, Staff and Student Fund for Graduate Fellowships',
        'amount': 0,
        'isLess': false,
        'isMore': false,
        'isDirty': false,
        'isInvalid': false
      }, {
        'pk': 'U1229',
        'uuid': 'U1229',
        'name': 'The Berkeley Basic Needs Fund',
        'title': 'The Berkeley Basic Needs Fund',
        'amount': 0,
        'isLess': false,
        'isMore': false,
        'isDirty': false,
        'isInvalid': false
      }, {
        'pk': 'X0222',
        'uuid': 'X0222',
        'name': 'The Cal Fund',
        'title': 'The Cal Fund',
        'amount': 0,
        'isLess': false,
        'isMore': false,
        'isDirty': false,
        'isInvalid': false
      }, {
        'pk': '999999',
        'uuid': 'other_id',
        'name': '',
        'title': '',
        'amount': 0,
        'isLess': false,
        'isMore': false,
        'isDirty': false,
        'isInvalid': false
      }]);
    },
    add: function add(item) {
      this.items.pushObject(item);
      this.updateCartTotal();
    },
    updateCartTotal: function updateCartTotal() {
      var total = this.items.reduce(function (accumulator, item) {
        var inputValue = item.amount;
        var amount = 0;
        if (inputValue) {
          if (inputValue.substring(0, 1) === '$') {
            amount = parseFloat(inputValue.substring(1).replace(/,/g, ''));
          } else {
            amount = parseFloat(inputValue.replace(/,/g, ''));
          }
        }
        if (isNaN(amount)) {
          return accumulator;
        } else {
          return accumulator + amount;
        }
      }, 0);
      this.set('total', total);
    },
    payLoad: function payLoad() {
      return {
        items: this.get('items'),
        total: this.get('total')
      };
    },
    pushPayload: function pushPayload(payLoad) {
      this.set('items', payLoad['items']);
      this.set('total', payLoad['total']);
    }
  });
  _exports.default = _default;
});