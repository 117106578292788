define("give-public/templates/payroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8poHFg+X",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-container\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"is_authenticated\"]]],null,{\"statements\":[[0,\"  \"],[7,\"main\"],[11,\"class\",\"page-container page-payroll\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"main-content-container\"],[9],[0,\"\\n      \"],[1,[23,\"page-header-logo-only\"],false],[0,\"\\n\\n      \"],[7,\"section\"],[11,\"class\",\"payroll-content gift-container\"],[9],[0,\"\\n        \"],[1,[29,\"payroll-form\",null,[[\"user\",\"class\"],[[25,[\"user\"]],\"basket-details\"]]],false],[0,\"\\n      \"],[10],[0,\"\\n\\n      \"],[1,[23,\"page-footer\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/payroll.hbs"
    }
  });
  _exports.default = _default;
});