define("give-public/services/biggive-phase", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    phaseDisplay: null,
    current_time: Ember.computed(function () {
      if (!Date.now) {
        Date.now = function now() {
          return new Date().getTime();
        };
      }
      return new Date(Date.now());
    }),
    currentPhase: null,
    preStartDate: '',
    preEndDate: '',
    liveStartDate: '',
    liveEndDate: '',
    postStartDate: '',
    postEndDate: '',
    prePhase: false,
    livePhase: false,
    postPhase: false,
    bodyClass: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.phaseDates = {};
    },
    initialize: function initialize() {
      var _this = this;
      var self = this;
      var phase = '';
      try {
        // parse API response and resolve these variables
        var phaseUrl = "".concat(_environment.default.APP.API_HOST, "/").concat(_environment.default.APP.API_NAMESPACE, "/biggive/phases/");
        // let phaseUrl = 'https://give-api-alpha.berkeley.website/v1/biggive/phases/';
        this.ajax.request(phaseUrl).then(function (response) {
          var results = response.results;
          var pre = results.filter(function (obj) {
            return obj.name === 'pre';
          });
          self.set('preStartDate', pre[0].start_datetime);
          self.set('preEndDate', pre[0].end_datetime);
          var live = results.filter(function (obj) {
            return obj.name === 'live';
          });
          self.set('liveStartDate', live[0].start_datetime);
          self.set('liveEndDate', live[0].end_datetime);
          var post = results.filter(function (obj) {
            return obj.name === 'post';
          });
          self.set('postStartDate', post[0].start_datetime);
          self.set('postEndDate', post[0].end_datetime);
          if (inRange(self.preStartDate, self.preEndDate, self.current_time)) {
            phase = 'prePhase';
            self.setPhase(phase);
            return phase;
          } else if (inRange(self.liveStartDate, self.liveEndDate, self.current_time)) {
            phase = 'livePhase';
            _this.setPhase(phase);
            return phase;
          } else if (inRange(self.postStartDate, self.postEndDate, self.current_time)) {
            phase = 'postPhase';
            _this.setPhase(phase);
            return phase;
          }
          function inRange(start, end, check) {
            var sDate, eDate, cDate;
            sDate = Date.parse(start);
            eDate = Date.parse(end);
            cDate = Date.parse(check);
            if (cDate <= eDate && cDate >= sDate) {
              return true;
            }
            return false;
          }
        });
      } catch (err) {
        console.log('Could not determine phase. Error: ' + err);
      }
    },
    overrideAndDeterminePhase: function overrideAndDeterminePhase(phaseQueryParam) {
      var phase = phaseQueryParam;
      switch (phase) {
        case 'pre':
          this.setPhase('prePhase');
          this.set('phaseDisplay', 'pre');
          break;
        case 'live':
          this.setPhase('livePhase');
          this.set('phaseDisplay', 'live');
          break;
        case 'post':
          this.setPhase('postPhase');
          this.set('phaseDisplay', 'post');
          break;
        default:
          {
            var datePhase = this.getPhase();
            if (typeof datePhase !== 'undefined') {
              this.set('phaseDisplay', datePhase.replace('Phase', ''));
            }
          }
      }
    },
    setPhase: function setPhase(phase) {
      switch (phase) {
        case 'prePhase':
          this.setProperties({
            prePhase: true,
            livePhase: false,
            postPhase: false
          });
          this.set('bodyClass', phase);
          this.set('currentPhase', 'pre');
          break;
        case 'livePhase':
          this.setProperties({
            prePhase: false,
            livePhase: true,
            postPhase: false
          });
          this.set('bodyClass', phase);
          this.set('currentPhase', 'live');
          break;
        case 'postPhase':
          this.setProperties({
            prePhase: false,
            livePhase: false,
            postPhase: true
          });
          this.set('bodyClass', phase);
          this.set('currentPhase', 'post');
          break;
      }
    },
    getPhase: function getPhase(phase) {
      switch (phase) {
        case 'prePhase':
          return this.prePhase;
        case 'livePhase':
          return this.livePhase;
        case 'postPhase':
          return this.postPhase;
      }
    }
  });
  _exports.default = _default;
});