define("give-public/app", ["exports", "give-public/resolver", "ember-load-initializers", "give-public/config/environment"], function (_exports, _resolver, _emberLoadInitializers, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });
  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App; // Make stuff available on all Controllers and Components
  _exports.default = _default;
  Ember.Controller.reopen({
    rootUrl: _environment.default.rootURL
  });
  Ember.Component.reopen({
    rootUrl: _environment.default.rootURL
  });
});