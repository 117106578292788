define("give-public/models/fundpage", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    pk: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    selected: _emberData.default.attr('boolean'),
    is_published: _emberData.default.attr('boolean'),
    is_athletics_fund: _emberData.default.attr('boolean'),
    is_athletics_benefits_fund: _emberData.default.attr('boolean'),
    amount: _emberData.default.attr('string'),
    description_short: _emberData.default.attr('string'),
    description_long: _emberData.default.attr('string'),
    contact_info: _emberData.default.attr(),
    image: _emberData.default.attr(),
    tags: _emberData.default.attr(),
    title: _emberData.default.attr('string'),
    areas_of_interest: _emberData.default.attr(),
    related_fund_pages: _emberData.default.attr()
  });
  _exports.default = _default;
});