define("give-public/components/phone-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
      var phone = this.get('value');
      if (phone) {
        this.validatePhone(phone);
      }
    },
    validatePhone: function validatePhone(phone) {
      var re = /^\+?([0-9]( |-|.)?)?(\(?[0-9]{3}\)?|[0-9]{3})( |-|.)?([0-9]{3}( |-|.)?[0-9]{4}|[a-zA-Z0-9]{7})$/;
      return phone.match(re);
    },
    actions: {
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // comma
          key === 188 ||
          // period
          key === 190 ||
          // decimal point
          key === 110 ||
          // (
          key === 57 ||
          // )
          key === 48 ||
          // +
          key === 187 ||
          // -
          key === 189 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      validateField: function validateField(event) {
        this.set('isDirty', true);
        var optional = this.get('required');
        if (optional !== 'optional') {
          var fieldValue = event.target.value;
          if (fieldValue && fieldValue.trim() !== '' && this.validatePhone(fieldValue)) {
            this.set('isInvalid', false);
          } else {
            this.set('isInvalid', true);
          }
        } else {
          this.set('isInvalid', false);
        }
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});