define("give-public/controllers/application", ["exports", "ember-local-storage", "ember-parachute", "give-public/config/environment"], function (_exports, _emberLocalStorage, _emberParachute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.bgQueryParams = void 0;
  // Ember Parachute allows easier access to query params
  var bgQueryParams = new _emberParachute.default({
    phase: {
      defaultValue: null
    },
    ac: {
      defaultValue: null
    }
  });
  _exports.bgQueryParams = bgQueryParams;
  var _default = Ember.Controller.extend(bgQueryParams.Mixin, {
    biggivePhase: Ember.inject.service(),
    vip: Ember.inject.service(),
    appealCode: (0, _emberLocalStorage.storageFor)('appeal'),
    tracking: Ember.inject.service(),
    queryParams: ['anc', 'open'],
    anc: null,
    open: null,
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    setup: function setup(_ref) {
      var queryParams = _ref.queryParams;
      // this.biggivePhase.overrideAndDeterminePhase(queryParams.phase);
      if (queryParams.ac === null) {
        var currentPhase = this.biggivePhase.getPhase('livePhase');
        if (currentPhase) {
          this.tracking.add({
            code: 'ac',
            value: "".concat(_environment.default.APP.defaultBigGiveTrackingCode)
          });
        }
        //   } else if (this.biggivePhase.getPhase('postPhase')) {
        //     queryParams.ac = '120958';
        //   } else {
        //     queryParams.ac = 'DCIWEB';
        //   }
      }

      this.vip.loadAppealCode(queryParams.ac);
      this.set('appealCode.ac', queryParams.ac);
    }
  });
  _exports.default = _default;
});