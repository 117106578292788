define("give-public/components/year-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    classNameBindings: ['isInvalid', 'isDirty'],
    init: function init() {
      this._super.apply(this, arguments);
      var year = this.get('value');
      if (year) {
        this.validateYear(year);
      }
    },
    validateYear: function validateYear(yearStr) {
      this.set('isDirty', true);
      var date = new Date();
      var currentYear = date.getFullYear();
      var currentMonth = date.getMonth() + 1;
      var recurringMonth = parseInt(this.cart.get('recurringMonth'));
      var year = parseInt(yearStr);
      var isValidYear = true;
      if (currentYear > year) {
        isValidYear = false;
      }
      if (currentYear == year && recurringMonth < currentMonth) {
        isValidYear = false;
      }
      if (!isValidYear) {
        this.set('isInvalid', true);
        this.cart.set('recurringMonthInvalid', true);
      } else {
        this.set('isInvalid', false);
      }
      if (this.cart.get('recurringMonthInvalid') || this.cart.get('recurringYearInvalid')) {
        this.set('isInvalid', true);
      }
      return isValidYear;
    },
    actions: {
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow enter, backspace, tab, delete, period, decimal point, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      updateCartYear: function updateCartYear(event) {
        var year = event.target.value;
        if (this.validateYear(year)) {
          this.cart.set('recurringYear', year);
          this.cart.updateCartGrandTotal();
          this.send('updateAttr', this.name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
        } else {
          this.cart.set('recurringTimes', null);
          this.cart.set('recurringYear', year);
          this.send('updateAttr', name, this.get('value'), this.get('isDirty'), this.get('isInvalid'));
        }
        return false;
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.sendAction('updateAttr', name, value, isDirty, isInvalid);
      }
    }
  });
  _exports.default = _default;
});