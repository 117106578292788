define("give-public/helpers/safe-html", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.safeHtml = safeHtml;
  function safeHtml(params /*, hash*/) {
    return Ember.String.htmlSafe(params);
  }
  var _default = Ember.Helper.helper(safeHtml);
  _exports.default = _default;
});