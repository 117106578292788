define("give-public/templates/pledgefailed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M58gyZRu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-container\"],[9],[0,\"\\n  \"],[1,[23,\"page-header-logo-only\"],false],[0,\"\\n  \"],[7,\"main\"],[11,\"class\",\"page-container page-pledge\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"main-container\"],[9],[0,\"\\n      \"],[7,\"section\"],[11,\"class\",\"pledge-content gift-container\"],[9],[0,\"\\n\\t\\t\\t\\t\"],[1,[29,\"pledge-information-details\",null,[[\"failed\"],[true]]],false],[0,\"\\n      \"],[10],[0,\"\\n      \"],[1,[23,\"page-footer\"],false],[0,\"\\n    \"],[10],[0,\"\\n\\t\"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/pledgefailed.hbs"
    }
  });
  _exports.default = _default;
});