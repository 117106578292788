define("give-public/components/search-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
    },
    counter: Ember.computed.alias('lastFunds.length'),
    actions: {
      goToFund: function goToFund(fund) {
        this.router.transitionTo('fund', fund.pk);
      },
      goToStaticPage: function goToStaticPage(fund) {
        this.router.transitionTo(fund.cleaned_path);
      },
      goToFundDrive: function goToFundDrive(fund) {
        this.router.transitionTo('funddrive', fund.fund_drive_id);
      },
      add: function add(fund) {
        var self = this;
        var id = fund.pk;
        var fundRecord = this.get('store').peekRecord('searchdetailcopy', id);
        if (fundRecord) {
          fundRecord.set('selected', true);
          self.cart.add(fund);
        }
      }
    }
  });
  _exports.default = _default;
});