define("give-public/components/basket-funds", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    pledgecart: Ember.inject.service(),
    cart: Ember.inject.service(),
    isMore: Ember.computed('cart.total', function () {
      return this.cart.total >= 100000;
    }),
    actions: {
      remove: function remove(item) {
        this.cart.remove(item, 'giftdetails');
        var items = this.cart.get('items');
        if (!items || items.length <= 0) {
          this.router.transitionTo('home');
        }
      },
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.pk);
      }
    }
  });
  _exports.default = _default;
});