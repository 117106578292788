define("give-public/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wrfHrhfu",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"body-container\"],[9],[0,\"\\n  \"],[7,\"main\"],[11,\"class\",\"page-container confirmation-container isSideBarShown\"],[9],[0,\"\\n    \"],[7,\"section\"],[11,\"class\",\"main-content-container\"],[9],[0,\"\\n      \"],[1,[29,\"page-header\",null,[[\"class\",\"toggleModal\"],[\"no-sidebar\",\"toggleModal\"]]],false],[0,\"\\n      \"],[7,\"div\"],[11,\"role\",\"main\"],[11,\"class\",\"main-container\"],[9],[0,\"\\n\\n        \"],[7,\"section\"],[11,\"class\",\"page-item\"],[9],[0,\"\\n          \"],[7,\"h2\"],[9],[0,\"Oops — something went wrong\"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"margin-top-20 margin-bottom-10 text-align\"],[9],[0,\"Thank you, your heart is in the right place — unfortunately,\\n          the system encountered an error with your browser.\"],[10],[0,\"\\n\\n        \"],[7,\"p\"],[11,\"class\",\"margin-bottom-20 text-align\"],[9],[0,\"If you get this message again, please \"],[7,\"a\"],[11,\"href\",\"mailto:“gifthelp@berkeley.edu”\"],[9],[0,\"email\"],[10],[0,\" or call UC Berkeley Gift Services at 510.643.9789 to complete your gift.\"],[10],[0,\"\\n          \"],[7,\"p\"],[11,\"class\",\"margin-bottom-20 text-align\"],[9],[0,\"Please provide them with the following information:\"],[10],[0,\"\\n        \"],[7,\"p\"],[11,\"class\",\"margin-bottom-20 text-align\"],[9],[0,\"\\n          Incident: \"],[1,[25,[\"incident\",\"id\"]],false],[0,\" \"],[7,\"br\"],[9],[10],[0,\"\\n          Logged on: \"],[1,[25,[\"incident\",\"challenge_ts\"]],false],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[10],[0,\"\\n\\n        \"],[1,[23,\"page-footer\"],false],[0,\"\\n      \"],[10],[0,\"\\n\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/error.hbs"
    }
  });
  _exports.default = _default;
});