define("give-public/templates/components/fund-progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "30xlUsJD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"progress-bar-container margin-bottom-20\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"label\"],[9],[0,\"\\n    \"],[1,[24,0,[\"progress\"]],false],[0,\" %\\n  \"],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"progress-bar\"],[11,\"role\",\"progressbar\"],[12,\"aria-valuenow\",[24,0,[\"progress\"]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[9],[0,\"      \\n\"],[4,\"if\",[[29,\"gt\",[[24,0,[\"progress\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bar\"],[12,\"style\",[30,[\"width: \",[24,0,[\"progress\"]],\"%;\"]]],[9],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"bar\"],[12,\"style\",[30,[\"width: \",[24,0,[\"progress\"]],\"%; border-right: none\"]]],[9],[0,\" \"],[10],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[10],[0,\"\\n\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/fund-progress-bar.hbs"
    }
  });
  _exports.default = _default;
});