define("give-public/components/basket-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    utilities: Ember.inject.service(),
    cart: Ember.inject.service(),
    tracking: Ember.inject.service(),
    router: Ember.inject.service(),
    classNameBindings: ['isInvalid'],
    biggivePhase: Ember.inject.service(),
    vip: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      if (this.get('isHonored')) {
        this.validateState();
      } else {
        this.setInitialHonorState();
      }
    },
    setInitialHonorState: function setInitialHonorState() {
      this.set('memoryFirstNameDirty', false);
      this.set('memoryFirstNameInvalid', true);
      this.set('memoryLastNameDirty', false);
      this.set('memoryLastNameInvalid', true);
      this.set('honorFirstNameDirty', false);
      this.set('honorFirstNameInvalid', true);
      this.set('honorLastNameDirty', false);
      this.set('honorLastNameInvalid', true);
      this.set('emailFirstNameDirty', false);
      this.set('emailFirstNameInvalid', true);
      this.set('emailLastNameDirty', false);
      this.set('emailLastNameInvalid', true);
      this.set('emailDirty', false);
      this.set('emailInvalid', true);
      this.set('mailFirstNameDirty', false);
      this.set('mailFirstNameInvalid', true);
      this.set('mailLastNameDirty', false);
      this.set('mailLastNameInvalid', true);
      this.set('mailStateDirty', false);
      this.set('mailStateInvalid', true);
      this.set('mailAddressDirty', false);
      this.set('mailAddressInvalid', true);
      this.set('mailCityDirty', false);
      this.set('mailCityInvalid', true);
      this.set('mailZipDirty', false);
      this.set('mailZipInvalid', true);
    },
    processFundAmount: function processFundAmount(inputValue) {
      var amount = 0;
      if (inputValue) {
        if (typeof inputValue == 'string' && inputValue.substring(0, 1) === '$') {
          amount = parseFloat(inputValue.substring(1).replace(/,/g, '')).toFixed(2);
        } else {
          amount = parseFloat(inputValue.replace(/,/g, '')).toFixed(2);
        }
      }
      return amount;
    },
    setShoppingCartDirtyState: function setShoppingCartDirtyState() {
      var _this = this;
      var items = JSON.parse(JSON.stringify(this.cart.get('items')));
      var updatedItems = items.map(function (fund) {
        var inputValue = fund.amount;
        var amount = _this.processFundAmount(inputValue);
        fund.isDirty = true;
        if (amount >= 10) {
          fund.isLess = false;
          fund.isInvalid = false;
          fund.isMore = false;
          if (amount >= 100000) {
            fund.isMore = true;
            fund.isInvalid = true;
          }
        } else {
          fund.isLess = true;
          fund.isInvalid = true;
          fund.isMore = false;
        }
        return fund;
      });
      this.cart.set('items', updatedItems);
    },
    setDirtyState: function setDirtyState() {
      this.setShoppingCartDirtyState();
      if (this.cart.get('isRecurring') && this.cart.get('isUntilDate')) {
        this.set('recurringMonthDirty', true);
        this.set('recurringYearDirty', true);
      }
      if (this.get('isHonored')) {
        if (this.get('isInMemory')) {
          this.set('memoryFirstNameDirty', true);
          this.set('memoryLastNameDirty', true);
        } else {
          this.set('honorFirstNameDirty', true);
          this.set('honorLastNameDirty', true);
        }
        if (this.get('isNotified')) {
          if (this.get('isEmail')) {
            this.set('emailFirstNameDirty', true);
            this.set('emailLastNameDirty', true);
            this.set('emailDirty', true);
          } else {
            this.set('mailFirstNameDirty', true);
            this.set('mailLastNameDirty', true);
            this.set('mailCountryDirty', true);
            this.set('mailStateDirty', true);
            this.set('mailAddressDirty', true);
            this.set('mailCityDirty', true);
            this.set('mailZipDirty', true);
          }
        }
      }
    },
    validateTime: function validateTime(validated) {
      var date = new Date();
      var currentYear = date.getFullYear();
      var currentMonth = date.getMonth() + 1;
      var recurringMonth = this.recurringMonth;
      var recurringYear = this.recurringYear;
      if (validated === undefined) {
        validated = true;
      }
      if (this.cart.recurringTimes < 2 || this.cart.frequency === "monthly" && this.cart.recurringTimes > 60 || this.cart.frequency === "quarterly" && this.cart.recurringTimes > 20 || this.cart.frequency === "annually" && this.cart.recurringTimes > 5) {
        validated = false;
        this.cart.set('recurringYearInvalid', true);
        this.cart.set('recurringMonthInvalid', true);
      } else if (currentYear > recurringYear) {
        validated = false;
        this.cart.set('recurringYearInvalid', true);
        this.cart.set('recurringMonthInvalid', true);
      } else if (currentYear == recurringYear && recurringMonth < currentMonth || recurringMonth < 1 || recurringMonth > 12) {
        validated = false;
        this.cart.set('recurringYearInvalid', true);
        this.cart.set('recurringMonthInvalid', true);
      } else if (this.cart.recurringTimes === null) {
        validated = false;
        this.cart.set('recurringYearInvalid', true);
        this.cart.set('recurringMonthInvalid', true);
      } else {
        this.cart.set('recurringYearInvalid', false);
        this.cart.set('recurringMonthInvalid', false);
      }
      return validated;
    },
    validateState: function validateState() {
      var validated = true;
      this.setDirtyState();
      var items = this.cart.get('items');
      if (!items || items.length <= 0) {
        validated = false;
      }
      for (var i = 0; i < items.length; i++) {
        var item = items[i];
        var inputValue = item.amount;
        item['isDirty'] = true;
        if (!inputValue || inputValue === '') {
          validated = false;
        } else {
          var amount = this.processFundAmount(inputValue);
          if (item['isInvalid'] || amount < 10 || amount > 100000) {
            validated = false;
          }
        }
      }
      if (this.cart.get('isRecurring') && this.cart.get('isUntilDate')) {
        validated = this.cart.validateTime(validated);
      }
      if (this.isHonored) {
        if (this.isInMemory) {
          if (this.memoryFirstName === '') {
            validated = false;
            this.set('memoryFirstNameInvalid', true);
          } else {
            this.set('memoryFirstNameInvalid', false);
          }
          if (this.memoryLastName === '') {
            validated = false;
            this.set('memoryLastNameInvalid', true);
          } else {
            this.set('memoryLastNameInvalid', false);
          }
        } else {
          if (this.honorFirstName === '') {
            validated = false;
            this.set('honorFirstNameInvalid', true);
          } else {
            this.set('honorFirstNameInvalid', false);
          }
          if (this.honorLastName === '') {
            validated = false;
            this.set('honorLastNameInvalid', true);
          } else {
            this.set('honorLastNameInvalid', false);
          }
        }
        if (this.isNotified) {
          if (this.isEmail) {
            if (this.isInMemory) {
              if (this.emailFirstName === '') {
                validated = false;
                this.set('emailFirstNameInvalid', true);
              } else {
                this.set('emailFirstNameInvalid', false);
              }
              if (this.emailLastName === '') {
                validated = false;
                this.set('emailLastNameInvalid', true);
              } else {
                this.set('emailLastNameInvalid', false);
              }
            }
            if (this.email === '') {
              validated = false;
              this.set('emailInvalid', true);
            } else {
              this.set('emailInvalid', false);
            }
          } else {
            if (this.isInMemory) {
              if (this.mailFirstName === '') {
                validated = false;
                this.set('mailFirstNameInvalid', true);
              } else {
                this.set('mailFirstNameInvalid', false);
              }
              if (this.mailLastName === '') {
                validated = false;
                this.set('mailLastNameInvalid', true);
              } else {
                this.set('mailLastNameInvalid', false);
              }
            }
            if (this.mailAddress === '') {
              validated = false;
              this.set('mailAddressInvalid', true);
            } else {
              this.set('mailAddressInvalid', false);
            }
            if (this.mailCity === '') {
              validated = false;
              this.set('mailCityInvalid', true);
            } else {
              this.set('mailCityInvalid', false);
            }
            if (this.mailZip === '') {
              validated = false;
              this.set('mailZipInvalid', true);
            } else {
              this.set('mailZipInvalid', false);
            }
          }
        }
      }
      return validated;
    },
    actions: {
      /***********************************
       * Begin BigGive Form Processing
       ***********************************
       * First Time Donor
       **********************************/
      toggleFirstTimeDonor: function toggleFirstTimeDonor() {
        this.sendAction('toggleFirstTimeDonor');
      },
      /***********************************
       *  Begin Cal Affiliation
       **********************************/
      toggleAlumni: function toggleAlumni() {
        this.sendAction('toggleAlumni');
      },
      toggleParentOrGuardian: function toggleParentOrGuardian() {
        this.sendAction('toggleParentOrGuardian');
      },
      toggleFriendOfCal: function toggleFriendOfCal() {
        this.sendAction('toggleFriendOfCal');
      },
      toggleFacultyStaff: function toggleFacultyStaff() {
        this.sendAction('toggleFacultyStaff');
      },
      toggleCurrentUndergrad: function toggleCurrentUndergrad() {
        this.sendAction('toggleCurrentUndergrad');
      },
      toggleCurrentGrad: function toggleCurrentGrad() {
        this.sendAction('toggleCurrentGrad');
      },
      toggleRetiree: function toggleRetiree() {
        this.sendAction('toggleRetiree');
      },
      /***********************************
       *  End Cal Affiliation
       ***********************************/
      setDonorWallPrefs: function setDonorWallPrefs(value) {
        this.sendAction('setDonorWallPrefs', value);
      },
      /* End BigGive Form Processing    */

      toggleHonored: function toggleHonored() {
        this.sendAction('toggleHonored');
      },
      honorSwitch: function honorSwitch(honor) {
        this.sendAction('honorSwitch', honor);
      },
      toggleMail: function toggleMail() {
        this.sendAction('toggleMail');
      },
      toggleAthleticsBenefitsElection: function toggleAthleticsBenefitsElection() {
        this.sendAction('toggleAthleticsBenefitsElection');
      },
      changeMailType: function changeMailType(mail) {
        this.sendAction('changeMailType', mail);
      },
      updateFieldValue: function updateFieldValue(name, value) {
        this.sendAction('updateFieldValue', name, value);
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.set("".concat(name, "Dirty"), isDirty);
        this.set("".concat(name, "Invalid"), isInvalid);
        if (name === 'recurringMonth' || name === 'recurringYear') {
          this.cart.validateTime();
        } else {
          this.send('updateFieldValue', name, value);
        }
        return false;
      },
      submitGifts: function submitGifts() {
        if (this.validateState() && this.cart.total < 100000 && this.cart.grandtotal < 100000) {
          this.sendAction('goTo', 'billingInformation');
          this.sendAction('resetBasketValidation', true);
        } else {
          this.setDirtyState();
          this.sendAction('resetBasketValidation', false);
        }
      }
    }
  });
  _exports.default = _default;
});