define("give-public/templates/components/tooltip-exceed", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6D04JOB4",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"content\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"type\"]],\"exceed\"],null]],null,{\"statements\":[[0,\"\\t\\tThank you for your generosity! Our giving site cannot process recurring gifts that span more than five years. Please adjust your end date or contact our Gift Services help desk by phone at 510.643.9789 or by email at \"],[7,\"a\"],[11,\"class\",\"underline_dots\"],[11,\"href\",\"mailto:gifthelp@berkeley.edu\"],[9],[0,\"gifthelp@berkeley.edu\"],[10],[0,\".\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"\\t\\tThank you for your generosity! Recurring gifts must comprise more than one payment. Please adjust your end date or choose the \\\"One Time\\\" gift frequency.\\n\"]],\"parameters\":[]}],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/tooltip-exceed.hbs"
    }
  });
  _exports.default = _default;
});