define("give-public/routes/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      var protoStaticPageList = this.store.peekAll('staticpage');
      if (protoStaticPageList.length < 1) {
        protoStaticPageList = this.store.findAll('staticpage');
      }
      var protoAreaList = this.store.peekAll('area');
      if (protoAreaList.length < 1) {
        protoAreaList = this.store.findAll('area');
      }
      var protoFeaturedFundList = this.store.peekAll('featuredfund');
      if (protoFeaturedFundList.length < 1) {
        protoFeaturedFundList = this.store.findAll('featuredfund');
      }
      var protoVip = this.store.peekAll('vip');
      if (protoVip.length < 1) {
        protoVip = this.store.findAll('vip');
      }
      var protoTakeoverList = this.store.peekAll('takeover');
      if (protoTakeoverList.length < 1) {
        protoTakeoverList = this.store.findAll('takeover');
      }
      return Ember.RSVP.hash({
        staticpages: protoStaticPageList,
        areas: protoAreaList,
        featuredfunds: protoFeaturedFundList,
        vip: protoVip,
        takeovers: protoTakeoverList
      });
    } /*params*/,
    actions: {
      error: function error() {
        this.intermediateTransitionTo('five00');
      }
    }
  });
  _exports.default = _default;
});