define("give-public/templates/components/index-areas", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "C7dHn2Qt",
    "block": "{\"symbols\":[\"aoi\"],\"statements\":[[7,\"h1\"],[11,\"class\",\"sub-title\"],[9],[0,\"What Would You Like to Support?\"],[10],[0,\" \\n\\n\"],[7,\"div\"],[11,\"class\",\"areas-wrapper\"],[9],[0,\"\\n  \"],[7,\"ul\"],[11,\"class\",\"list-container areas\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"featuredAreas\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"goToArea\",[24,1,[]]],null]],[11,\"role\",\"button\"],[11,\"class\",\"interesting-area-container\"],[11,\"title\",\"Go to the Area of Interest\"],[9],[0,\"\\n      \"],[7,\"li\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"interesting-area text-center\"],[9],[0,\" \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"aoi-title\"],[9],[1,[24,1,[\"name\"]],false],[10],[0,\"\\n      \"],[10],[0,\"\\n\\t\\t\"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"h1\"],[11,\"class\",\"sub-title\"],[9],[0,\"Featured Funds\"],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/index-areas.hbs"
    }
  });
  _exports.default = _default;
});