define("give-public/templates/components/side-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZaLqgIRb",
    "block": "{\"symbols\":[\"fund\"],\"statements\":[[7,\"aside\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"role\",\"button\"],[11,\"class\",\"error-icon\"],[9],[0,\"X\"],[3,\"action\",[[24,0,[]],\"closeModal\"]],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"clearfix\"],[9],[10],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"gift-button-container\"],[9],[0,\"\\n    \"],[7,\"img\"],[11,\"class\",\"gift-button text-center\"],[11,\"alt\",\"Gift Basket Icon\"],[12,\"src\",[30,[[23,\"rootURL\"],\"/assets/img/gift-basket.svg\"]]],[9],[10],[0,\"\\n\"],[4,\"if\",[[29,\"gt\",[[25,[\"cart\",\"counter\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"basket-counter\"],[9],[1,[25,[\"cart\",\"counter\"]],false],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n  \"],[7,\"h3\"],[11,\"class\",\"mini-title text-center\"],[9],[0,\"Your Gift Basket\"],[10],[0,\"\\n\\n  \"],[1,[23,\"cart-contents\"],false],[0,\"\\n\\n\"],[4,\"if\",[[29,\"lte\",[[25,[\"cart\",\"counter\"]],0],null]],null,{\"statements\":[[0,\"  \"],[7,\"section\"],[11,\"class\",\"featured-funds-container margin-top-50\"],[9],[0,\"\\n    \"],[7,\"h3\"],[11,\"class\",\"sub-title\"],[9],[0,\"Some Suggestions:\"],[10],[0,\"\\n\\n    \"],[7,\"ul\"],[11,\"class\",\"list-container\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"suggestedfunds\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"featured-fund-container margin-bottom-10\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"featured-fund\"],[9],[0,\"\\n            \"],[7,\"a\"],[11,\"role\",\"button\"],[11,\"class\",\"fund-title\"],[12,\"onclick\",[29,\"action\",[[24,0,[]],\"goToFund\",[24,1,[]]],null]],[9],[1,[24,1,[\"title\"]],false],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/side-bar.hbs"
    }
  });
  _exports.default = _default;
});