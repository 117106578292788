define("give-public/components/pledge-form", ["exports", "@babel/runtime/helpers/esm/createForOfIteratorHelper", "give-public/config/environment"], function (_exports, _createForOfIteratorHelper2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // this API is provided by IAS
  // it receives GET requests and returns pledge details for only 1 or 2 pledges
  var pledgeApiEndpoint = _environment.default.APP.pledgeApiEndpoint;
  var _default = Ember.Component.extend({
    ajax: Ember.inject.service('ajax'),
    isShowingModal: false,
    pledgecart: Ember.inject.service(),
    submitButton: 'Send',
    init: function init() {
      this._super.apply(this, arguments);
    },
    setDirtyState: function setDirtyState() {
      this.set('donorIdDirty', true);
      this.set('pledgeNum1Dirty', true);
    },
    validateState: function validateState() {
      var validated = true;
      this.setDirtyState();
      if (this.pledgecart.get('donorId').trim() === '') {
        validated = false;
        this.set('donorIdInvalid', true);
      } else {
        this.set('donorIdInvalid', false);
      }
      if (this.pledgecart.get('pledgeNum1').trim() === '') {
        validated = false;
        this.set('pledgeNum1Invalid', true);
      } else {
        this.set('pledgeNum1Invalid', false);
      }
      this.pledgecart.set('pledgesRetrieved', false);
      return validated;
    },
    actions: {
      updateFieldValue: function updateFieldValue(name, value) {
        this.set("".concat(name), value);
      },
      updateAttr: function updateAttr(name, value, isDirty, isInvalid) {
        this.set("".concat(name, "Dirty"), isDirty);
        this.set("".concat(name, "Invalid"), isInvalid);
        // this.send('updateFieldValue', name, value);
      },
      submitPledge: function submitPledge() {
        var _this = this;
        if (this.validateState()) {
          var donorId = this.pledgecart.get('donorId');
          var pledgeNum1 = this.pledgecart.get('pledgeNum1');
          var pledgeNum2 = this.pledgecart.get('pledgeNum2');
          var pledgeNum3 = this.pledgecart.get('pledgeNum3');
          var cadsParam = "cads_id=".concat(donorId);
          var pledgeParam1 = "&pledge_id=".concat(pledgeNum1);
          var pledgeParam2 = pledgeNum2 ? "&pledge_id2=".concat(pledgeNum2) : '';
          var pledgeParam3 = pledgeNum3 ? "&pledge_id3=".concat(pledgeNum3) : '';
          var pledgeApiCall = pledgeApiEndpoint + cadsParam + pledgeParam1 + pledgeParam2 + pledgeParam3;
          this.get('ajax').request(pledgeApiCall).then(function (data) {
            var pledges = data['data'];
            if (pledges && Array.isArray(pledges) && pledges.length > 0) {
              var _iterator = (0, _createForOfIteratorHelper2.default)(pledges),
                _step;
              try {
                for (_iterator.s(); !(_step = _iterator.n()).done;) {
                  var pledge = _step.value;
                  if (pledge['allocation_code'] && pledge['long_name']) {
                    // create shopping cart item
                    var item = {
                      id: pledge.allocation_code,
                      pk: pledge.allocation_code,
                      non_give_fund: pledge.allocation_code,
                      amount: 0,
                      selected: true,
                      name: pledge.long_name,
                      title: pledge.long_name,
                      fund_name: pledge.long_name
                    };
                    _this.pledgecart.add(item);
                  }
                }
              } catch (err) {
                _iterator.e(err);
              } finally {
                _iterator.f();
              }
              _this.pledgecart.set('pledgesRetrieved', true);
              _this.pledgecart.set('pledgeApiError', false);
            } else {
              // no pledges found
              _this.pledgecart.set('pledgesRetrieved', false);
              _this.pledgecart.set('pledgeApiError', true);
            }
          }).catch(function () {
            _this.pledgecart.set('pledgesRetrieved', false);
            _this.pledgecart.set('pledgeApiError', true);
          });
        }
      }
    }
  });
  _exports.default = _default;
});