define("give-public/components/list-pagination", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    page: 1,
    paginateBy: 10,
    showNext: true,
    nextText: 'next page',
    previousText: 'previous page',
    currentPage: 1,
    // ember lifecycle hooks
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      var page = 1;
      this.set('page', page);
      var totalItems = this.get('items.length');
      var pageinateBy = parseInt(this.paginateBy);
      var numberOfPages = this.updatePageCount(totalItems, pageinateBy);
      this.set('numberOfPages', numberOfPages);
      this.set('showNext', this.updateShowNext(page, numberOfPages));
    },
    updatePageCount: function updatePageCount(totalItems, paginateBy) {
      return Math.ceil(totalItems / paginateBy);
    },
    // function that handles next arrow display
    updatePager: function updatePager() {
      this.updateShowNext();
      this.updateShowPrevious();
      window.scrollTo(0, 0);
    },
    updateShowNext: function updateShowNext() {
      var page = this.page;
      var numberOfPages = this.numberOfPages;
      this.set('showNext', page < numberOfPages);
      return page < numberOfPages;
    },
    updateShowPrevious: function updateShowPrevious() {
      var page = this.page;
      this.set('showPrevious', page > 1);
      if (page > 1) {
        return true;
      } else {
        this.updateShowNext();
        return false;
      }
    },
    paginatedItems: Ember.computed('items', 'page', function () {
      if (this.items) {
        var i = (parseInt(this.page) - 1) * parseInt(this.paginateBy);
        var j = i + parseInt(this.paginateBy);
        return this.items.slice(i, j);
      } else {
        return;
      }
    }),
    numberOfPages: Ember.computed('page', function () {
      if (this.items) {
        var n = this.get('items.length');
        var c = parseInt(this.paginateBy);
        return this.updatePageCount(n, c);
      } else {
        return;
      }
    }),
    pageNumbers: Ember.computed('numberOfPages', function () {
      var n = Array(this.numberOfPages);
      for (var i = 0; i < n.length; i++) {
        n[i] = i + 1;
      }
      return n;
    }),
    showPrevious: Ember.computed('page', function () {
      return this.page > 1;
    }),
    pageStart: Ember.computed('items', 'page', function () {
      return (parseInt(this.page) - 1) * parseInt(this.paginateBy) + 1;
    }),
    pageEnd: Ember.computed('items', 'page', function () {
      if (this.updateShowNext()) {
        return this.pageStart + parseInt(this.paginateBy) - 1;
      } else {
        return this.get('items.length');
      }
    }),
    // events
    keyDown: function keyDown(event) {
      // Perform search on ENTER press
      if (event.keyCode === 13) {
        event.preventDefault(); // DO NOT REMOVE! Breaks Page!     
        return false;
      }
    },
    // actions
    actions: {
      nextClicked: function nextClicked() {
        if (this.page + 1 <= this.numberOfPages) {
          var nextPage = this.page + 1;
          this.set('page', nextPage);
          this.set('currentPage', nextPage);
          this.updatePager();
        }
      },
      previousClicked: function previousClicked() {
        if (this.page > 0) {
          var previousPage = this.page - 1;
          this.set('page', previousPage);
          this.set('currentPage', previousPage);
          this.updateShowPrevious();
        }
      },
      pageClicked: function pageClicked(pageNumber) {
        this.set('page', pageNumber);
      },
      checkInput: function checkInput(e) {
        var key = e.charCode || e.which || e.keyCode || 0;
        // allow backspace, tab, delete, numbers, keypad numbers, home, end only.
        return (
          // Enter
          key === 13 ||
          // backspace
          key === 8 ||
          // tab
          key === 9 ||
          // delete
          key === 46 ||
          // end, home, left arrow, up arrow, down arrow, right arrow
          key >= 35 && key <= 40 ||
          // 0 - 9
          key >= 48 && key <= 57 ||
          // numpad 0 - 9
          key >= 96 && key <= 105
        );
      },
      goToPage: function goToPage(event) {
        var pageNumber = parseInt(event.target.value);
        if (typeof pageNumber == 'number' && pageNumber > 0 && pageNumber <= this.get('numberOfPages')) {
          this.set('page', pageNumber);
          this.updatePager();
        }
      },
      resetCurrentPage: function resetCurrentPage(event) {
        var pageNumber = event.target.value;
        if (pageNumber === '') {
          var page = this.get('page');
          this.set('currentPage', page);
        }
      }
    }
  });
  _exports.default = _default;
});