define("give-public/templates/components/turnstile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lmdvMbO3",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"cf-turnstile\"],[11,\"id\",\"cf-turnstile-widget\"],[12,\"data-sitekey\",[23,\"sitekey\"]],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "give-public/templates/components/turnstile.hbs"
    }
  });
  _exports.default = _default;
});