define("give-public/controllers/pledgeconfirmation", ["exports", "give-public/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    utilities: Ember.inject.service(),
    isShowingModal: false,
    router: Ember.inject.service(),
    cart: Ember.inject.service(),
    pledgecart: Ember.inject.service(),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      this.cart.empty();
      var utilities = this.get('utilities');
      var gift_id = window.localStorage.getItem('pledge_gift_id');
      if (gift_id) {
        this.set('gift_id', gift_id);
        var app_host = _environment.default.APP.HOST;
        this.set('app_host', app_host);
        utilities.getPledgeTransactionResult(gift_id).then(function (data) {
          _this.set('receipt', data);
          if (data.content) {
            if (data.content.image) {
              _this.set('hero_image', data.content.image);
            }
            if (data.content.message) {
              _this.set('content_message', data.content.message);
            }
          }
          var total = _this.calculateTotal(data.basket.items);
          _this.set('total', total);
          var current_date = new Date(data.current_date);
          var payment_date = current_date.getDate();
          _this.set('payment_date', payment_date);
          window.localStorage.removeItem('pledgecart');
        });
      }
    },
    calculateTotal: function calculateTotal(items) {
      var total = items.reduce(function (accumulator, item) {
        var amount = parseFloat(item.amount);
        return accumulator + amount;
      }, 0);
      return total;
    },
    actions: {
      toggleModal: function toggleModal() {
        this.toggleProperty('isShowingModal');
      },
      selectResult: function selectResult(result) {
        this.transitionToRoute('fund', result);
      },
      goToFund: function goToFund(item) {
        this.router.transitionTo('fund', item.fund_id);
      }
    }
  });
  _exports.default = _default;
});