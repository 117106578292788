define("give-public/components/modal-dialog", ["exports", "ember-modal-dialog/components/modal-dialog", "ember-keyboard"], function (_exports, _modalDialog, _emberKeyboard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _modalDialog.default.extend(_emberKeyboard.EKMixin, {
    init: function init() {
      this._super.apply(this, arguments);
      this.set('keyboardActivated', true);
    },
    closeOnEsc: Ember.on((0, _emberKeyboard.keyDown)('Escape'), function () {
      this.get('onClose')();
    })
  });
  _exports.default = _default;
});