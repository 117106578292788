define("give-public/components/area-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      window.scrollTo(0, 0);
      var slug = this.get('slug').toLowerCase().split('-').map(function (s) {
        return s.charAt(0).toUpperCase() + s.substring(1);
      }).join(' ');
      this.set('slug', slug);
    },
    counter: Ember.computed.alias('areadetails.length'),
    actions: {
      goToFund: function goToFund(fund) {
        this.router.transitionTo('fund', fund.id);
      },
      add: function add(fund) {
        var self = this;
        var id = fund.id;
        var fundRecord = this.get('store').peekRecord('areadetail', id);
        if (fundRecord) {
          fundRecord.set('selected', true);
          self.cart.add(fundRecord);
        }
      }
    }
  });
  _exports.default = _default;
});