define("give-public/services/turnstile-state", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    renderCount: 0,
    destroyCount: 0,
    token: null,
    addToken: function addToken(token) {
      this.token = token;
      //console.log('Token '+token+' set successfully.');
    }
  });
  _exports.default = _default;
});